import { useEffect, useState } from 'react';
import { API, Auth } from 'aws-amplify';

import {
  Heading,
  Text,
  View,
  useAuthenticator,
  Loader,
  Flex,
  Button,
  Collection,
} from '@aws-amplify/ui-react';

//import Data from './data.json';

export function MarketingReport({ updateProfiles, aProfile, updateAProfile }) {

  const [isLoading, setLoading] = useState(true);
  const [marketingData, setMarketingData] = useState([]);
  const [showUsers, setShowUsers] = useState(false);
  const [usersData, setUsersData] = useState({});

  const { user } = useAuthenticator((context) => [context.user]);

  async function getMarketingReport() {

    /* 
    await setMarketingData(Data);
    setLoading(false);
*/
    var userLocal = undefined;
    try {
      userLocal = await Auth.currentAuthenticatedUser();
    } catch (error) {
      userLocal = user;
    };
    //when just signed out both user & userLocal are undefined
    try {
      const requestReport = {
        headers: {
          Authorization: `${userLocal.signInUserSession.idToken.jwtToken}`
        }
      };
      const data = await API.get("gcAPI", `/dashboard-marketing-report`, requestReport);
      await setMarketingData(data);
      setLoading(false);
    } catch (e) {
      console.log(e);
    };
  }

  useEffect(() => {
    //call API to get marketing report data
    getMarketingReport();
    //eslint-disable-next-line
  }, [aProfile]);

  function MarketingReport() {
//console.log(marketingData);
    const result = marketingData;

    function createMonthDivs() {
      const returnResponseArr = [];
      let monthName = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
      var d = new Date();
      d.setDate(1);
      for (var i = 0; i <= 5; i++) {
        returnResponseArr.push(d.getFullYear() + '-' + monthName[d.getMonth()]);
        d.setMonth(d.getMonth() - 1);
      }
      returnResponseArr.push('older');
      return returnResponseArr;
    }

    const divsArray = createMonthDivs();

    const allDashboard = (
      <Flex
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        alignContent="center"
        wrap="nowrap"
        gap="2rem"
      >
        <View key={0} size={1}>
          <div>
            <br /><br />
            <b>REGISTER</b>
          </div>
          <div>
            <br /><br />
            <b>VERIFY</b>
          </div>
          <div>
            <br />
            <b>PROFILE_START</b>
          </div>
          <div>
            <br /><br />
            <b>PROFILE_COMPLETED</b>
          </div>
          <div>
            <br /><br />
            <b>TRY</b>
          </div>
          <div>
            <br />
            <b>BOOST</b>
          </div>
          <div>
          <br /><br />
            <b>SUBSCRIBE</b>
          </div>
        </View>
        {divsArray.map((div, i) => (
          <View key={i + 1} size={1}>
            {/* ACQUISITION */}
            <div>
              <b>{div}</b>
              <br /><br />

              <div className="progress" data-toggle="tooltip" data-placement="top" data-container="body" title="REGISTER: 100%">
                <div
                  className="progress-bar"
                  aria-valuenow="100"
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{ minWidth: '2em', width: '100%', backgroundColor: '#08529b' }}
                  onClick={() => { setShowUsers(true); setUsersData({ "div": div, "stage": 'ACQUISITION' }) }}
                >
                  {result.Data[div].ACQUISITION.all}
                </div>
              </div>
            </div>

            {/* VERIFY */}
            <div>
              <br /><br />
              <div className="progress" data-toggle="tooltip" data-placement="top" data-container="body" title={`VERIFY: ${(result.Data[div].VERIFY.all / result.Data[div].ACQUISITION.all) * 100}%`}>
                <div
                  className="progress-bar"
                  aria-valuenow={(result.Data[div].VERIFY.all / result.Data[div].ACQUISITION.all) * 100}
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{ minWidth: '2em', width: `${(result.Data[div].VERIFY.all / result.Data[div].ACQUISITION.all) * 100}%`, backgroundColor: '#08529b' }}
                  onClick={() => { setShowUsers(true); setUsersData({ "div": div, "stage": 'VERIFY' }) }}
                >
                  {result.Data[div].VERIFY.all}
                </div>
              </div>
            </div>

            {/* PROFILE_START */}
            <div>
              <br /><br />
              <div
                className="progress"
                data-toggle="tooltip"
                data-placement="top"
                data-container="body"
                title={`PROFILE_START: ${(result.Data[div].PROFILE_START.all / result.Data[div].VERIFY.all) * 100}%`}
              >
                <div
                  className="progress-bar"
                  aria-valuenow={(result.Data[div].PROFILE_START.all / result.Data[div].VERIFY.all) * 100}
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{ minWidth: '2em', width: `${(result.Data[div].PROFILE_START.all / result.Data[div].VERIFY.all) * 100}%`, backgroundColor: '#08529b' }}
                  onClick={() => { setShowUsers(true); setUsersData({ "div": div, "stage": 'PROFILE_START' }) }}
                >
                  {result.Data[div].PROFILE_START.all}
                </div>
              </div>
            </div>

            {/* PROFILE_COMPLETED */}
            <div>
              <br /><br />
              <div
                className="progress"
                data-toggle="tooltip"
                data-placement="top"
                data-container="body"
                title={`PROFILE_COMPLETED: ${(result.Data[div].PROFILE_COMPLETED.all / result.Data[div].PROFILE_START.all) * 100}%`}
              >
                <div
                  className="progress-bar"
                  aria-valuenow={(result.Data[div].PROFILE_COMPLETED.all / result.Data[div].PROFILE_START.all) * 100}
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{ minWidth: '2em', width: `${(result.Data[div].PROFILE_COMPLETED.all / result.Data[div].PROFILE_START.all) * 100}%`, backgroundColor: '#08529b' }}
                  onClick={() => { setShowUsers(true); setUsersData({ "div": div, "stage": 'PROFILE_COMPLETED' }) }}
                >
                  {result.Data[div].PROFILE_COMPLETED.all}
                </div>
              </div>
            </div>

            {/* TRY */}
            <div>
              <br /><br />
              <div
                className="progress"
                data-toggle="tooltip"
                data-placement="top"
                data-container="body"
                title={`TRY: ${(result.Data[div].TRY.all / result.Data[div].PROFILE_COMPLETED.all) * 100}%`}
              >
                <div
                  className="progress-bar"
                  aria-valuenow={(result.Data[div].TRY.all / result.Data[div].PROFILE_COMPLETED.all) * 100}
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{ minWidth: '2em', width: `${(result.Data[div].TRY.all / result.Data[div].PROFILE_COMPLETED.all) * 100}%`, backgroundColor: '#08529b' }}
                  onClick={() => { setShowUsers(true); setUsersData({ "div": div, "stage": 'TRY' }) }}
                >
                  {result.Data[div].TRY.all}
                </div>
              </div>
            </div>

            {/* BOOST */}
            <div>
              <br /><br />
              <div
                className="progress"
                data-toggle="tooltip"
                data-placement="top"
                data-container="body"
                title={`BOOST: ${(result.Data[div].BOOST.all / result.Data[div].TRY.all) * 100}%`}
              >
                <div
                  className="progress-bar"
                  aria-valuenow={(result.Data[div].BOOST.all / result.Data[div].TRY.all) * 100}
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{ minWidth: '2em', width: `${(result.Data[div].BOOST.all / result.Data[div].TRY.all) * 100}%`, backgroundColor: '#08529b' }}
                  onClick={() => { setShowUsers(true); setUsersData({ "div": div, "stage": 'BOOST' }) }}
                >
                  {result.Data[div].BOOST.all}
                </div>
              </div>
            </div>

            {/* SUBSCRIBE */}
            <div>
              <br /><br />
              <div
                className="progress"
                data-toggle="tooltip"
                data-placement="top"
                data-container="body"
                title={`SUBSCRIBE: ${(result.Data[div].SUBSCRIBE.all / result.Data[div].TRY.all) * 100}%`}
              >
                <div
                  className="progress-bar"
                  aria-valuenow={(result.Data[div].SUBSCRIBE.all / result.Data[div].TRY.all) * 100}
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{ minWidth: '2em', width: `${(result.Data[div].SUBSCRIBE.all / result.Data[div].TRY.all) * 100}%`, backgroundColor: '#08529b' }}
                  onClick={() => { setShowUsers(true); setUsersData({ "div": div, "stage": 'SUBSCRIBE' }) }}
                >
                  {result.Data[div].SUBSCRIBE.all}
                </div>
              </div>
            </div>
          </View>
        ))}
      </Flex>
    );

    const usersList = ({ div, stage }) => {
      let stageName = stage;
      if (stage === 'ACQUISITION') stageName = 'REGISTER';
      return (
      <>
        <View align="center">
          <Text variation="secondary">
            {`List of users registered in ${div} still at stage ${stageName}`}
          </Text>
          <Button variation='small' onClick={() => {setShowUsers(false); setUsersData({})}}>Back to report Home</Button>
        </View>
        <br /><br />
        <Collection
          items={result.Data[div][stage].users}
          type="list"
        >
              {(item, index) => (

                <div key={index} align="left">
                  <span>{item.name}</span>&nbsp;&nbsp;&nbsp;
                  <span>{item.email}</span>&nbsp;&nbsp;&nbsp;
                  <span>{item.created}</span>&nbsp;&nbsp;&nbsp;
                  <span>{item.uuid}</span>
                </div>
              )}
        </Collection>
      </>
    )};
    return (showUsers ? usersList(usersData) : allDashboard);
  }

  return (
    <View align="center">
      <Heading level={3} fontWeight={'bold'} textAlign="center">
        Marketing Report
      </Heading>
      <View align="center">
        <Text variation="secondary">
          Key Performance Indicators for Growth
        </Text>
      </View>
      <br />
      <br />
      {isLoading ? <Loader variation="linear" /> : <MarketingReport />}
    </View>
  )
}