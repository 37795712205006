import { useState, useEffect } from 'react';
import { API, Auth, Storage } from 'aws-amplify';

import {
    Heading,
    Expander,
    Link,
    Text,
    View,
    Collection,
    Rating,
    Loader,
    ExpanderItem,
    Image,
    Flex,
    Divider,
    Tabs,
    TabItem,
    Badge
} from '@aws-amplify/ui-react';

export function CampaignHistory({ aProfile }) {

    const [isLoading, setLoading] = useState(true);

    const [campaigns, setCampaigns] = useState([]);

    let regexp = /android|iphone|kindle|ipad/i;
    let isMobileDevice = regexp.test(navigator.userAgent);

    const [isMobile] = useState(isMobileDevice);

    async function getCampaigns() {
        const userUpdate = await Auth.currentAuthenticatedUser();
        const requestCampaigns = {
            headers: {
                Authorization: `${userUpdate.signInUserSession.idToken.jwtToken}`
            }
        };
        const data = await API.get("gcAPI", `/get-campaigns/${userUpdate.username}`, requestCampaigns);
        data.sort((a, b) => {
            return new Date(b.RequestTime) - new Date(a.RequestTime);
        });
        setCampaigns(await Promise.all(data.map(async (el) => {
            el.avatarUrl = await Storage.get(el.avatarUrl, {
                level: "protected",
                identityId: userUpdate.username
            });
            el.screenshot = await Storage.get(el.screenshot, {
                level: "protected",
                identityId: userUpdate.username
            });
            if ("screenshotFinal" in el) {
                el.screenshotFinal = await Storage.get(el.screenshotFinal, {
                    level: "protected",
                    identityId: userUpdate.username
                });
            };
            return { ...el }
        })));
        setLoading(false);
    };

    const [tasks, setTasks] = useState([]);

    async function getTasks(forProfile) {
        const userUpdate = await Auth.currentAuthenticatedUser();
        const requestTasks = {
            headers: {
                Authorization: `${userUpdate.signInUserSession.idToken.jwtToken}`
            }
        };
        const data = await API.get("gcAPI", `/get-tasks/${userUpdate.username + '_' + encodeURIComponent(forProfile.profileUrl)}`, requestTasks);
        setTasks(await Promise.all(data.map(async (el) => {
            el.avatarUrl = await Storage.get(el.avatarUrl, {
                level: "protected",
                identityId: el.authorUser
            });
            return { ...el }
        })));
        setLoading(false);
    };

    // eslint-disable-next-line
    useEffect(() => {
        getCampaigns();
        getTasks(aProfile);
    }, [aProfile]);

    function TasksTabs({ tasks }) {
        function getTasksByStage(stage) {
            //sort tasks by RequestTime
            tasks.sort((a, b) => {
                return new Date(b.RequestTime) - new Date(a.RequestTime);
            });
            return tasks.filter((el) => el.stage === stage);
        }

        function TasksByStage({ tasks }) {
            return (
                <Collection
                    direction='column'
                    type='list'
                    items={tasks}
                    isPaginated
                    itemsPerPage={20}
                >
                    {(item, index) => (
                        <View key={index} padding="1rem">
                            <Expander isCollapsible={true} maxWidth="vw">
                                <ExpanderItem title={
                                    <Flex
                                        wrap="nowrap"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        width="100%"
                                    >

                                        <img src={item.avatarUrl} style={{ borderRadius: '50%', maxWidth: '50px', paddingTop: '0.5rem', paddingBottom: '0.5rem' }} alt={item.name} />
                                        {item.needsIntro ? "REPOST + " : ""}{item.needsComment ? "COMMENT + " : ""}LIKE&nbsp;[{item.like}]
                                        <Flex
                                            wrap="nowrap"
                                            alignItems="center"
                                            justifyContent="flex-end"
                                            width="100%"
                                        >
                                            {"pods" in item && item.pods.length > 0 ? <Badge>{item.pods}</Badge> : null}                                            
                                            <Text as="span"><b>Earn</b> {item.requestType && item.requestType.indexOf("PAID-") > -1 ? <>{item.quantity}0$<br />for 50</> : item.requestType === "PAID" ? <>5$<br />for 25</> : item.quantity}
                                                <Rating
                                                    value={1}
                                                    maxValue={1}
                                                    fillColor="hsl(51, 100%, 50%)"
                                                />
                                            </Text>
                                        </Flex>
                                    </Flex>
                                }
                                    value={'initial-' + index}
                                    maxWidth="100%"
                                >
                                    <Image
                                        src={item.screenshot}
                                        style={{ maxWidth: '100%' }}
                                    />
                                    <br />
                                    <Text as="span"><b>Post URL:</b> <Link href={isMobile && item.postUrl ? item.postUrl.replace('https://www.linkedin.com', 'linkedin://') : item.postUrl} target='_blank' rel="noreferrer">OPEN</Link></Text>
                                    <br />
                                    {item.needsComment ? <Text as="span"><b>COMMENT:</b> {item.comment}<br /></Text> : ""}
                                    <br />
                                    {item.needsIntro ? <Text as="span"><b>REPOST with suggested text:</b> {item.intro}<br /></Text> : ""}
                                    <br />
                                    {"msg" in item ? <Text as="span"><b>MSG:</b> {item.msg}<br /></Text> : ""}
                                    <br />
                                </ExpanderItem>
                            </Expander>
                        </View>
                    )}
                </Collection>
            )
        }

        const [index, setIndex] = useState(0);

        return (
            <Tabs currentIndex={index} onChange={(i) => setIndex(i)} spacing="equal">
                <TabItem title={isMobile ? "Att" : "Attempted"}>
                    <TasksByStage tasks={getTasksByStage("attempted")} />
                </TabItem>
                <TabItem title={isMobile ? "Done" : "Completed"}>
                    <TasksByStage tasks={getTasksByStage("completed")} />
                </TabItem>
                <TabItem title={isMobile ? "Not val" : "Not validated"}>
                    <TasksByStage tasks={getTasksByStage("not_validated")} />
                </TabItem>
                <TabItem title={isMobile ? "Skip" : "Skipped"}>
                    <TasksByStage tasks={getTasksByStage("rejected")} />
                </TabItem>
                {/*<TabItem title={isMobile ? "T out" : "Timedout"}>
                    <TasksByStage tasks={getTasksByStage("timedout")} />
                </TabItem>*/}
            </Tabs>
        );
    }

    return (
        <View align="center">
            {Object.keys(aProfile).length > 0 ?
                <View maxWidth="1000px" align="left">
                    <Heading level={3} fontWeight={'bold'} textAlign="center" color={"#414c5a"}>
                        Tasks History
                    </Heading>
                    <View align="center">
                        <Text variation="secondary">
                            <br />
                            Reload the page in the browser to see the recent changes (it takes a bit to load).
                        </Text>
                    </View>
                    <br />
                    {isLoading ? <Loader variation="linear" /> : <TasksTabs tasks={tasks} />}
                </View>
                :
                <View maxWidth="1000px" align="left">
                    <Heading level={3} fontWeight={'bold'} textAlign="center" color={"#414c5a"}>
                        Campaigns History
                    </Heading>
                    <View align="center">
                        <Text variation="secondary">
                            <br />
                            Reload the page in the browser to see the recent changes (it takes a bit to load).
                            <br />
                            All results have a time delay between 15 minutes and 3 hours.
                        </Text>
                    </View>
                    <br />
                    {isLoading ? <Loader variation="linear" /> : <Collection
                        direction='column'
                        type='list'
                        items={campaigns}
                    >
                        {(item, index) => (
                            <View key={index} padding="1rem">
                                <Flex
                                    wrap="nowrap"
                                    alignItems="center"
                                >

                                    <img src={item.avatarUrl} style={{ borderRadius: '50%', maxWidth: '50px' }} alt={item.name} />

                                    {item.name}
                                </Flex>
                                <br />
                                <Expander isCollapsible={true} maxWidth="vw">
                                    <ExpanderItem title="Initial Post Screenshot"
                                        value={'initial-' + index}
                                        maxWidth="100%"
                                    >
                                        <Image
                                            src={item.screenshot}
                                            style={{ maxWidth: '100%' }}
                                        />
                                    </ExpanderItem>
                                </Expander>
                                <Expander isCollapsible={true}>
                                    <ExpanderItem title="Final Post Screenshot"
                                        value={'final-' + index}
                                    >
                                        {"screenshotFinal" in item ? <Image
                                            src={item.screenshotFinal}
                                            style={{ maxWidth: '100%' }}
                                        /> : "NOT YET AVAILABLE"}
                                    </ExpanderItem>
                                </Expander>
                                <br />
                                <Text as="span"><b>Post URL:</b> <Link href={isMobile ? item.postUrl.replace('https://www.linkedin.com', 'linkedin://') : item.postUrl} target='_blank' rel="noreferrer">OPEN</Link></Text>
                                <br />
                                <Text as="span"><b>Activity:</b> {item.requestType}</Text>
                                <br />
                                {"pods" in item && item.pods.length > 0 ? <Text as="span"><b>PODS:</b> {item.pods}<br /></Text> : ""}
                                <Text as="span"><b>Budget:</b> {item.quantity}{item.requestType.indexOf('PAID') > -1 ? "$" : <Rating
                                    value={1}
                                    maxValue={1}
                                    fillColor="hsl(51, 100%, 50%)"
                                />}
                                </Text>
                                <br />
                                <Text as="span"><b>Date:</b> {item.StartTime}</Text>
                                <br />
                                <Text as="span"><b>Stage:</b> {item.stage}</Text>
                                <br />
                                {"tasksCreated" in item ? <Text as="span"><b>TASKS:</b> {item.tasksCreated}<br /></Text> : ""}
                                <br />
                                <Divider orientation="horizontal" size="large" />
                            </View>
                        )}
                    </Collection>}
                </View>}
        </View>
    )
}