import { useEffect, useState } from "react";
import { API, Auth, Storage } from "aws-amplify";

import {
    Heading,
    TextField,
    Button,
    Text,
    View,
    Link,
    Loader,
} from "@aws-amplify/ui-react";

import { useNavigate } from "react-router-dom";

export function AddProfile({
    updateProfiles,
    aProfile,
    updateAProfile,
    clientData,
}) {
    const linkedInAuthUrl = async () => {
        const clientId = "77dyuiqhdxf7a5"; // replace with your LinkedIn app client ID
        const redirectUri = encodeURIComponent(
            "https://api.1stinfluencer.com/prod/social-auth/linkedin"
        ); // replace with your redirect URI - api social-auth
        //get current IP address using ifconfig.me
        let requestIP = "";

        try {
            requestIP = await fetch("https://ifconfig.me/ip").then((response) =>
                response.text()
            );

            if (requestIP.length > 15 || requestIP.length < 7) requestIP = "";
        } catch (e) {
            console.log("IP error: ", e.message);
        }

        const state = requestIP + "-" + clientData; // any unique string
        const scope = encodeURIComponent(
            "r_basicprofile r_emailaddress r_1st_connections_size"
        );

        return `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&state=${state}&scope=${scope}`;
    };

    const navigate = useNavigate();

    const hostname = window.location.hostname;

    const [urlHasError, setUrlHasError] = useState(false);

    const [newProfileStart, setNewProfileStart] = useState({
        loading: false,

        response: null,
    });

    useEffect(() => {
        if (Object.keys(aProfile).length > 0)
            setNewProfileStart({
                loading: false,
                data: aProfile,
                ifValidate: true,
                profileUrl: aProfile.profileUrl,
                state: true,
                response: null,
            });
    }, [aProfile]);

    async function addNewProfile(ifValidate) {
        if (
            document
                .getElementById("profileUrl")
                .value.indexOf("linkedin.com") < 0 &&
            document.getElementById("profileUrl").value.indexOf("lnkd.in") < 0
        ) {
            setUrlHasError(true);
        } else {
            setUrlHasError(false);
            setNewProfileStart({
                loading: true,
                state: ifValidate,
            });
            //call api to check URL, make & return screenshot
            //we expect profile URL error OR
            //returned screenshot to show and ask for text-code in Headline
            const user = await Auth.currentAuthenticatedUser();
            var requestStartNewCampaing = {
                headers: {
                    Authorization: `${user.signInUserSession.idToken.jwtToken}`,
                },
                body: {
                    profileUrl: document.getElementById("profileUrl").value,
                    ifValidate: ifValidate,
                    ifRetry: false,
                },
            };
            try {
                //setUrlHasError(false);
                var data = await API.post(
                    "gcAPI",
                    `/post-addProfile/${user.username}`,
                    requestStartNewCampaing
                );
                if (data.profiles) {
                    var sortedProfiles = data.profiles.sort(
                        (a, b) => Number(b.validated) - Number(a.validated)
                    );
                    updateProfiles(
                        await Promise.all(
                            sortedProfiles.map(async (el) => {
                                el.avatarUrl = await Storage.get(el.avatarUrl, {
                                    level: "protected",
                                    identityId: user.username,
                                });
                                return { ...el };
                            })
                        )
                    );
                }
                //console.log('1st try success: ', data, data.data.profileId);
                if (Object.keys(aProfile).length > 0) {
                    aProfile.validated = data.data.validated;
                    aProfile.profileId = data.data.profileId;
                    updateAProfile(aProfile);
                }
                setUrlHasError(!data.state);
                setNewProfileStart(data);
            } catch (e) {
                //console.log('initial error: ', e.message);
                //the error should be a timeout only
                try {
                    //console.log('2nd try...');
                    document.getElementById("loaderText").innerHTML =
                        "30 more seconds...";
                    requestStartNewCampaing.body.ifRetry = true;
                    data = await API.post(
                        "gcAPI",
                        `/post-addProfile/${user.username}`,
                        requestStartNewCampaing
                    );
                    //console.log('2nd try success: ', data, data.data.profileId);
                    if (data.profiles) {
                        sortedProfiles = data.profiles.sort(
                            (a, b) => Number(b.validated) - Number(a.validated)
                        );
                        updateProfiles(
                            await Promise.all(
                                sortedProfiles.map(async (el) => {
                                    el.avatarUrl = await Storage.get(
                                        el.avatarUrl,
                                        {
                                            level: "protected",
                                            identityId: user.username,
                                        }
                                    );
                                    return { ...el };
                                })
                            )
                        );
                    }
                    if (Object.keys(aProfile).length > 0) {
                        aProfile.validated = data.data.validated;
                        aProfile.profileId = data.data.profileId;
                        updateAProfile(aProfile);
                    }
                    setUrlHasError(!data.state);
                    setNewProfileStart(data);
                } catch (error) {
                    //console.log('retry error: ', error.message);
                    //also timeout error
                    try {
                        //2nd retry, 3rd try in total
                        //console.log('3rd try...');
                        document.getElementById("loaderText").innerHTML =
                            "another 30 seconds...";
                        requestStartNewCampaing.body.ifRetry = true;
                        data = await API.post(
                            "gcAPI",
                            `/post-addProfile/${user.username}`,
                            requestStartNewCampaing
                        );
                        //console.log('3rd try success: ', data, data.data.profileId);
                        if (data.profiles) {
                            sortedProfiles = data.profiles.sort(
                                (a, b) =>
                                    Number(b.validated) - Number(a.validated)
                            );
                            updateProfiles(
                                await Promise.all(
                                    sortedProfiles.map(async (el) => {
                                        el.avatarUrl = await Storage.get(
                                            el.avatarUrl,
                                            {
                                                level: "protected",
                                                identityId: user.username,
                                            }
                                        );
                                        return { ...el };
                                    })
                                )
                            );
                        }
                        if (Object.keys(aProfile).length > 0) {
                            aProfile.validated = data.data.validated;
                            aProfile.profileId = data.data.profileId;
                            updateAProfile(aProfile);
                        }
                        setUrlHasError(!data.state);
                        setNewProfileStart(data);
                    } catch (err) {
                        //console.log('3rd try error: ', err.message);
                        //also timeout error
                        setUrlHasError(true);
                        setNewProfileStart({
                            loading: false,
                            state: false,
                            response: err.message,
                        });
                    }
                }
            }
        }
    }

    return (
        <View align="center">
            <View maxWidth="1000px" align="left">
                <Heading level={3} fontWeight={"bold"} textAlign="center">
                    {Object.keys(aProfile).length > 0 ? (
                        <>Complete Profile Validation</>
                    ) : (
                        <>Add Profile</>
                    )}
                </Heading>
                <View align="center">
                    <Text variation="secondary">
                        <br />
                        Validate LinkedIn profile ownership.
                        <br />
                    </Text>
                    {hostname.includes("1stinfluencer") && (
                        <>
                            <br />
                            <br />
                            <Heading level={4} textAlign="center">
                                1. Start with official LinkedIn Sign-In :
                            </Heading>
                            <br />
                            <img
                                src="/1stinfluencer/linkedin/Sign-In-Small---Default.png"
                                alt="Sign in with LinkedIn"
                                onClick={async () => {
                                    let url = await linkedInAuthUrl();
                                    window.location.href = url;
                                }}
                                style={{ cursor: "pointer" }}
                                onMouseOver={(e) =>
                                    (e.target.src =
                                        "/1stinfluencer/linkedin/Sign-In-Small---Hover.png")
                                }
                                onMouseOut={(e) =>
                                    (e.target.src =
                                        "/1stinfluencer/linkedin/Sign-In-Small---Default.png")
                                }
                                onMouseDown={(e) =>
                                    (e.target.src =
                                        "/1stinfluencer/linkedin/Sign-In-Small---Active.png")
                                }
                                onMouseUp={(e) =>
                                    (e.target.src =
                                        "/1stinfluencer/linkedin/Sign-In-Small---Default.png")
                                }
                            />
                        </>
                    )}
                    {
                        //We won't get any access to it or show it publicly.
                    }
                </View>
                <br />
                <br />
                {!hostname.includes("1stinfluencer") && (
                    <>
                        <b>Step 1.</b> Paste profile URL here:
                        {Object.keys(aProfile).length > 0 ? (
                            <TextField
                                id="profileUrl"
                                hasError={urlHasError}
                                disabled={newProfileStart.state}
                                onChange={(e) => {}}
                                errorMessage="please fill in correct LinkedIn profile URL"
                                placeholder="e.g. https://www.linkedin.com/in/YourName ..."
                                value={aProfile.profileUrl}
                            />
                        ) : (
                            <TextField
                                id="profileUrl"
                                hasError={urlHasError}
                                disabled={newProfileStart.state}
                                errorMessage="please fill in correct LinkedIn profile URL"
                                placeholder="e.g. https://www.linkedin.com/in/YourName ..."
                            />
                        )}
                        <br />
                        {newProfileStart.loading ? (
                            <>
                                <View align="center">
                                    <br />
                                    <span id="loaderText">
                                        Please, wait about a minute for the
                                        loader to stop
                                    </span>
                                    <br />
                                </View>
                                <Loader variation="linear" />
                            </>
                        ) : (
                            <>
                                {newProfileStart.profileUrl &&
                                newProfileStart.data &&
                                !newProfileStart.data.validated ? (
                                    <>
                                        <b>Step 2.</b> Copy-paste code text to
                                        anywhere in your profile Headline:
                                        <br />
                                        <br />
                                        <Heading
                                            level={4}
                                            fontWeight={"bold"}
                                            textAlign="center"
                                        >
                                            {newProfileStart.data.profileId}
                                        </Heading>
                                        <br />
                                        <View align="center">
                                            <b>Your headline for example:</b>{" "}
                                            {newProfileStart.data.headline}
                                            &nbsp;
                                            <b>
                                                {newProfileStart.data.profileId}
                                            </b>
                                            {!newProfileStart.data.validated &&
                                            newProfileStart.ifValidate ? (
                                                <Text color="red">
                                                    Can't find that code text in
                                                    your profile headline{" "}
                                                    {newProfileStart.response}
                                                </Text>
                                            ) : (
                                                <></>
                                            )}
                                            <br />
                                            <br />
                                            You cand delete that code from your
                                            headline after validation is
                                            confirmed.
                                        </View>
                                        <br />
                                        <b>Step 3.</b> Validate code presence
                                        <br />
                                        <br />
                                        <View align="center">
                                            {!newProfileStart.loading ? (
                                                <Button
                                                    variation={
                                                        newProfileStart.loading
                                                            ? "disabled"
                                                            : "primary"
                                                    }
                                                    type="submit"
                                                    onClick={() =>
                                                        addNewProfile(true)
                                                    }
                                                >
                                                    {newProfileStart.loading ? (
                                                        <>
                                                            <Loader />
                                                            &nbsp;PROCESSING...
                                                        </>
                                                    ) : (
                                                        "VALIDATE"
                                                    )}
                                                </Button>
                                            ) : (
                                                <></>
                                            )}
                                        </View>
                                    </>
                                ) : (
                                    <View align="center">
                                        {newProfileStart.data &&
                                        newProfileStart.data.validated ? (
                                            <>
                                                <Heading level={2}>
                                                    Congrats!
                                                </Heading>
                                                <Heading level={4}>
                                                    Your profile control is
                                                    validated!
                                                </Heading>
                                                <Text variation="secondary">
                                                    <br />
                                                    You now safe to delete that
                                                    code from your profile
                                                    Headline.
                                                </Text>
                                                <br />
                                                <Button
                                                    variation={"primary"}
                                                    type="submit"
                                                    onClick={async () => {
                                                        const user =
                                                            await Auth.currentAuthenticatedUser();
                                                        newProfileStart.data.avatarUrl =
                                                            await Storage.get(
                                                                newProfileStart
                                                                    .data
                                                                    .avatarUrl,
                                                                {
                                                                    level: "protected",
                                                                    identityId:
                                                                        user.username,
                                                                }
                                                            );
                                                        updateAProfile(
                                                            newProfileStart.data
                                                        );
                                                        navigate("/tasks");
                                                    }}
                                                >
                                                    Start with TASKS
                                                </Button>
                                            </>
                                        ) : (
                                            <>
                                                <Button
                                                    variation="primary"
                                                    type="submit"
                                                    onClick={() =>
                                                        addNewProfile(false)
                                                    }
                                                >
                                                    {newProfileStart.loading ? (
                                                        <>
                                                            <Loader />
                                                            &nbsp;PROCESSING...
                                                        </>
                                                    ) : (
                                                        "ADD PROFILE"
                                                    )}
                                                </Button>
                                            </>
                                        )}
                                    </View>
                                )}
                            </>
                        )}
                        {hostname.indexOf("1st") > -1 ? (
                            <></>
                        ) : (
                            <View align="center">
                                <br />
                                Check video guide{" "}
                                <Link
                                    href="https://youtu.be/LhTSRBBlIf0"
                                    target="_blank"
                                >
                                    here
                                </Link>
                            </View>
                        )}
                    </>
                )}
            </View>
        </View>
    );
}
