import { useState } from 'react';
import { API, Auth } from 'aws-amplify';

import {
    Heading,
    TextField,
    SelectField,
    SwitchField,
    Button,
    View,
    Rating,
    StepperField,
    Loader,
    Link,
    Flex,
    Expander,
    ExpanderItem,
    TextAreaField
} from '@aws-amplify/ui-react';

import Modal, { ModalBody, ModalFooter, ModalHeader } from './modal/Modal';

import { useNavigate } from "react-router-dom";

const basicBudget = 5;

function Countdown() {

    //make animated loader in form of digital clock countdown 
    //starting from 1:00,0
    //and going down 1 tenth of second per step
    //when it reaches 0:00,0 continue with negative numbers

    var time = 60 * 10;  // start from 1:00:0
    var timerId = setInterval(countdown, 100);  // update every 0.1 second

    function countdown() {
        // Display the timer
        var minutes = Math.floor(time / 600);
        var seconds = Math.floor((time % 600) / 10);
        var tenths = time % 10;
        if (document.getElementById("timer") !== null) document.getElementById("timer").innerHTML = minutes + ":" + seconds + ":" + tenths;

        // Stop the countdown when it reaches 0:00:0
        if (time === 0) {
            clearInterval(timerId);
        }

        time--;
    }

    return (
        <div id="timer"></div>
    )
}

export function NewCampaign({ balance, updateBalance, subscription, clientData }) {

    const navigate = useNavigate();
    const [budget, setBudget] = useState(['lite', 'pro'].includes(clientData.subscriptionPlan) ? 1 : basicBudget);
    const handleOnStepChange = (newValue) => { setBudget(newValue); };

    const hostname = window.location.hostname;

    const [showModal, setShowModal] = useState(false);

    const [localClientData, setLocalClientData] = useState(clientData);

    const [urlHasError, setUrlHasError] = useState(false);

    const [newCampaignStart, setNewCampaignStart] = useState({
        loading: false,
        state: null,
        response: null
    });

    const [minStep, setMinStep] = useState(['lite', 'pro'].includes(clientData.subscriptionPlan) ? 1 : basicBudget);

    const [paid, setPaid] = useState(subscription);
    const [selection, setSelection] = useState(subscription ? "PAID" : "FREE");

    async function startNewCampaing() {

        setNewCampaignStart({
            loading: true
        });

        if (((document.getElementById("postUrl").value.indexOf("linkedin.com") < 0) &&
            (document.getElementById("postUrl").value.indexOf("lnkd.in") < 0)) || (document.getElementById("postUrl").value.indexOf("[…]") > -1)) {
            setUrlHasError(true);
            setNewCampaignStart({
                loading: false
            });
        } else {
            setUrlHasError(false);
            setShowModal(true);
            let regexp = /android|iphone|kindle|ipad/i;
            let isMobileDevice = regexp.test(navigator.userAgent);
            const user = await Auth.currentAuthenticatedUser();
            var requestStartNewCampaing = {
                headers: {
                    Authorization: `${user.signInUserSession.idToken.jwtToken}`
                },
                body: {
                    campaignBudget: (paid && ['lite', 'pro'].includes(clientData.subscriptionPlan)) ? budget * 5 : budget,
                    postUrl: document.getElementById("postUrl").value,
                    actityType: document.getElementById("activityType").value,
                    deviceMobile: isMobileDevice,
                    ifRetry: false
                }
            };

            if (paid) {
                document.getElementById("comments") ? requestStartNewCampaing.body.comments = document.getElementById("comments").value : requestStartNewCampaing.body.comments = '';
                document.getElementById("intros") ? requestStartNewCampaing.body.intros = document.getElementById("intros").value : requestStartNewCampaing.body.intros = '';
                document.getElementById("pods") ? requestStartNewCampaing.body.pods = document.getElementById("pods").value : requestStartNewCampaing.body.pods = '';
            };

            //call gcAPI until it returns success and make every next call after 1st
            //with ifRetry=true
            //if it returns error after 3 tries, show error message
            //if it returns success, update balance and show success message
            try {
                const data = await API.post("gcAPI", `/post-newCampaign`, requestStartNewCampaing);
                //console.log(data);
                if (data.state && (document.getElementById("activityType").value.indexOf("FREE") > -1)) {
                    updateBalance(balance - budget);
                    document.getElementById("postUrl").value = '';
                    document.getElementById("budget-stepper").value = basicBudget;
                    setBudget(basicBudget);
                }
                //console.log(data.response);
                if (data.response.indexOf('Not enough ') > -1) { } else {
                    data.response = "Your request was processed successfully! Check campaign progress in the HISTORY."
                };
                setNewCampaignStart(data);
            } catch (e) {
                try {
                    requestStartNewCampaing.body.ifRetry = true;
                    const data = await API.post("gcAPI", `/post-newCampaign`, requestStartNewCampaing);
                    //console.log(data);
                    if (data.state && (document.getElementById("activityType").value.indexOf("FREE") > -1)) {
                        updateBalance(balance - budget);
                        document.getElementById("postUrl").value = '';
                        document.getElementById("budget-stepper").value = basicBudget;
                        setBudget(basicBudget);
                    }
                    if (data.response.indexOf('Not enough STARS left. Please go to TASKS to earn STARS') >= 0) { } else {
                        data.response = "Your request was processed successfully! Check campaign progress in the HISTORY."
                    };
                    setNewCampaignStart(data);
                } catch (error) {
                    try {
                        requestStartNewCampaing.body.ifRetry = true;
                        const data = await API.post("gcAPI", `/post-newCampaign`, requestStartNewCampaing);
                        //console.log(data);
                        if (data.state && (document.getElementById("activityType").value.indexOf("FREE") > -1)) {
                            updateBalance(balance - budget);
                            document.getElementById("postUrl").value = '';
                            document.getElementById("budget-stepper").value = basicBudget;
                            setBudget(basicBudget);
                        }
                        if (data.response.indexOf('Not enough STARS left. Please go to TASKS to earn STARS') >= 0) { } else {
                            data.response = "Your request was processed successfully! Check campaign progress in the HISTORY."
                        };
                        setNewCampaignStart(data);
                    } catch (error) {
                        console.log(e.message);
                        setNewCampaignStart({
                            loading: false,
                            state: false,
                            response: "Your request need few more minutes to process. Please check HISTORY to see if campaign created and reload the page to check for balance change."
                        });
                    }
                }
            };
        };
    };

    function SubscriberSettings() {
        return (<>
            <Flex justifyContent="center">
                <Expander
                    label="Custom comments & reposts intros (optional)"
                    isCollapsible={true}
                    maxWidth="vw"
                >
                    <ExpanderItem title={"Brand brief (optional)"}
                        maxWidth="100%"
                        value='options'
                    >
                        <b>Comments & reposts intros examples</b><br /><br />
                        <TextAreaField
                            id="comments"
                            value={localClientData.comments}
                            label="Comments"
                            onChange={(e) => {
                                setLocalClientData({ ...localClientData, comments: e.target.value });
                            }}
                            placeholder={"comments" in localClientData ? '' : 'loading...'}
                        />
                        <b>How to use comments guide</b>
                        <ul>
                            <li>Comments are written 1 per line</li>
                            <li>It is great for brand to control campaign content</li>
                            <li>Empty comments lead to no comments or AI suggested comments mostly</li>
                            <li>We save the comments every boost, so you may replace comments with new, once in a while</li>
                            <li>Every time the comments are used in random order</li>
                            <li>To disable comments make sure this field is empty before starting new campaign</li>
                            <li>Saved comments are updated with every new campaign start</li>
                            <li>Refresh the page to make sure the latest data is fetched from the server</li>
                        </ul>
                        <br />
                        <TextAreaField
                            id="intros"
                            label="Re-posts intros"
                            value={localClientData.intros}
                            onChange={(e) => {
                                setLocalClientData({ ...localClientData, intros: e.target.value });
                            }}
                            placeholder={"intros" in localClientData ? '' : 'loading...'}
                        />

                        <b>How to use intros guide</b>
                        <ul>
                            <li>Write 1 intro per line starting with semicolon (<b>;</b>)</li>
                            <li>It is great for brand to control campaign content</li>
                            <li>Empty intros lead to repost without text or AI suggested repost intros mostly</li>
                            <li>Your intros are suggested in random order</li>
                            <li>To disable intros make sure this field is empty before starting new campaign</li>
                            <li>Saved intros are updated with every new campaign start</li>
                            <li>Refresh the page to make sure the latest data is fetched from the server</li>
                        </ul>
                    </ExpanderItem>

                </Expander>

            </Flex>
            <br />
            <Flex justifyContent="center">
                <Expander
                    label="Custom filters & groups (optional)"
                    isCollapsible={true}
                    maxWidth="vw"
                >
                    <ExpanderItem title={"Groups & custom filters (optional)"}
                        maxWidth="100%"
                        value='options'
                    >
                        <TextAreaField
                            id="pods"
                            value={localClientData.pods ? localClientData.pods.split(' ').join('\n') : ''}
                            label="Groups & custom filters"
                            onChange={(e) => {
                                setLocalClientData({ ...localClientData, pods: e.target.value });
                            }}
                            placeholder={"pods" in localClientData ? '' : 'loading...'}
                        />
                        <b>How to use groups & custom filters guide</b>
                        <ul>
                            <li>If not empty, we show this campaign to only profiles with a minimum one matching filter</li>
                            <li>Names of the groups and custom filters are the same</li>
                            <li>Write names 1 per line</li>
                            <li>Use easy to copy, meaningful and short names</li>
                            <li>Good examples are one word company/brand names, like Pepsi, Sephora, Mac</li>
                            <li>To easy copy the group/filter name, do tap+hold on mobile or mouse double click on it</li>
                            <li>Profiles with groups/filters in settings see first only campaigns with matching filters</li>
                            <li>To disable groups make sure this field is empty before starting new campaign</li>
                            <li>Saved groups/filters are updated with every new campaign start</li>
                            <li>Refresh the page to make sure the latest data is fetched from the server</li>
                        </ul>
                    </ExpanderItem>

                </Expander>
            </Flex>
        </>
        )
    }

    return (
        <>
            <View align="center">
                <View maxWidth="1000px" align="left">
                    <Heading level={3} fontWeight={'bold'} textAlign="center" color={"#414c5a"}>
                        3 simple steps to start<br />
                        LinkedIn influencer campaign
                    </Heading>

                    <br />
                    <b>Step 1.</b> Paste your post URL here:
                    <TextField
                        id="postUrl"
                        hasError={urlHasError}
                        errorMessage="please fill in correct LinkedIn post URL"
                        placeholder="e.g. https://www.linkedin.com/posts/LongString ..."
                    />
                    <br />

                    <b>Step 2.</b> Choose activities:

                    {hostname.indexOf("1st") > -1 ?
                        <></>
                        :
                        <Flex
                            alignItems="center"
                            direction="column"
                        >
                            <SwitchField
                                label={paid ? "PAID" : "FREE"}
                                isDisabled={!subscription}
                                isChecked={paid}
                                onChange={(e) => {
                                    setPaid(e.target.checked);
                                    if (selection.indexOf('-') > -1) { //PAID-REPOST FREE-REPOST
                                        //if likes - 5 if reposts/post - 50 or 25$
                                        if (e.target.checked) {
                                            setSelection('PAID-REPOST');
                                            if (['lite', 'pro'].includes(clientData.subscriptionPlan)) {
                                                setMinStep(2);
                                                setBudget(2);
                                            } else {
                                                setMinStep(10);
                                                setBudget(10);
                                            };
                                        } else {
                                            setMinStep(50);
                                            setBudget(50);
                                        };
                                    } else { //FREE PAID
                                        if ((e.target.checked) && ['lite', 'pro'].includes(clientData.subscriptionPlan)) {
                                            setMinStep(1);
                                            setBudget(1);
                                            setSelection('PAID');
                                        } else {
                                            setMinStep(basicBudget);
                                            setBudget(basicBudget);
                                        };
                                    }
                                }}
                                size='large'
                            />
                        </Flex>
                    }
                    {paid ?
                        <br />
                        :
                        subscription ? <br /> :
                        <Flex justifyContent="center">
                            <small>
                                Unlock with subscription <Link href="" onClick={() => navigate("/billing")}><u>here</u></Link>
                            </small>
                        </Flex>
                    }

                    {paid ?
                        <>
                            <Flex justifyContent="center">
                                <StepperField
                                    max={['lite', 'pro'].includes(clientData.subscriptionPlan) ? clientData.postBalance : 10}
                                    min={minStep}
                                    step={minStep}
                                    onStepChange={handleOnStepChange}
                                    id="budget-stepper"
                                    value={budget}
                                />
                                {hostname.indexOf("1st") > -1 ?
                                    <SelectField id="activityType" onChange={
                                        (e) => {
                                            setSelection(e.target.value);
                                            if (e.target.value.indexOf('-') > -1) { //REPOST
                                                if (paid) {
                                                    if (['lite', 'pro'].includes(clientData.subscriptionPlan)) {
                                                        setMinStep(2);
                                                        setBudget(2);
                                                    } else {
                                                        setMinStep(10);
                                                        setBudget(10);
                                                    };
                                                } else {
                                                    setMinStep(50);
                                                    setBudget(50);
                                                };
                                            } else {
                                                if (paid && ['lite', 'pro'].includes(clientData.subscriptionPlan)) {
                                                    setMinStep(1);
                                                    setBudget(1);
                                                } else {
                                                    setMinStep(basicBudget);
                                                    setBudget(basicBudget);
                                                };
                                            };
                                        }
                                    }
                                        defaultValue='PAID'
                                    >
                                        <option value="PAID">Promo campaign</option>
                                        <option value="PAID-REPOST">Promo w Re-posts</option>
                                        <option value="PAID-POST" disabled>Create & Promote</option>
                                    </SelectField>
                                    :
                                    <SelectField id="activityType" onChange={
                                        (e) => {
                                            setSelection(e.target.value);
                                            if (e.target.value.indexOf('-') > -1) { //REPOST
                                                if (paid) {
                                                    if (['lite', 'pro'].includes(clientData.subscriptionPlan)) {
                                                        setMinStep(2);
                                                        setBudget(2);
                                                    } else {
                                                        setMinStep(10);
                                                        setBudget(10);
                                                    };
                                                } else {
                                                    setMinStep(50);
                                                    setBudget(50);
                                                };
                                            } else {
                                                if (paid && ['lite', 'pro'].includes(clientData.subscriptionPlan)) {
                                                    setMinStep(1);
                                                    setBudget(1);
                                                } else {
                                                    setMinStep(basicBudget);
                                                    setBudget(basicBudget);
                                                };
                                            };
                                        }
                                    }
                                        defaultValue='PAID'
                                    >
                                        <option value="PAID">Likes and Comments</option>
                                        <option value="PAID-REPOST">Re-posts</option>
                                        <option value="PAID-POST" disabled>Posts</option>
                                    </SelectField>
                                }
                            </Flex>
                            <br />
                            {subscription ?
                                SubscriberSettings()
                                :
                                ""
                            }
                            <br />
                            <b>Step 3.</b> Confirm and start: <br />
                            <Heading level={3} fontWeight={'bold'} textAlign="center">
                                <br />
                                {budget} {['lite', 'pro'].includes(clientData.subscriptionPlan) ? "campaigns to use" : "$"}
                                <br />
                                <br />
                                <Button variation={newCampaignStart.loading ? "disabled" : "primary"} type="submit" onClick={() => startNewCampaing()}>{newCampaignStart.loading ? <><Loader />&nbsp;{['lite', 'pro'].includes(clientData.subscriptionPlan) ? "" : "PAY & "}START CAMPAIGN</> : ['lite', 'pro'].includes(clientData.subscriptionPlan) ? "START CAMPAIGN" : "PAY & START CAMPAIGN"}</Button>
                                <br />
                            </Heading>
                        </>
                        :
                        hostname.indexOf("1st") > -1 ?
                            <>
                            </>
                            :
                            <>
                                <Flex justifyContent="center">
                                    <StepperField
                                        max={balance ? balance : 0}
                                        min={minStep}
                                        step={minStep}
                                        onStepChange={handleOnStepChange}
                                        id="budget-stepper"
                                        value={budget}
                                    />
                                    <SelectField id="activityType" onChange={
                                        //flip minStep between 5 and 50; 5 for FREE, 50 for FREE-REPOST & FREE-POST
                                        (e) => {
                                            setSelection(e.target.value);
                                            if (e.target.value.indexOf('-') > -1) { //REPOST
                                                setMinStep(50);
                                                setBudget(50);
                                            } else {
                                                setMinStep(basicBudget);
                                                setBudget(basicBudget);
                                            };
                                        }
                                    }
                                        defaultValue={selection}
                                    >
                                        <option value="FREE">Likes and Comments</option>
                                        <option value="FREE-REPOST">Re-posts</option>
                                        <option value="FREE-POST" disabled>Posts</option>
                                    </SelectField>
                                </Flex>
                                <br />
                                {subscription ?
                                    SubscriberSettings()
                                    :
                                    ""
                                }
                                <br />
                                <b>Step 3.</b> Confirm and start: <br />
                                <Heading level={3} fontWeight={'bold'} textAlign="center">
                                    <br />
                                    {budget}<Rating
                                        value={1}
                                        maxValue={1}
                                        fillColor="hsl(51, 100%, 50%)"
                                    />
                                    <br />
                                    <br />
                                    {(balance - budget >= 0) ? <Button variation={newCampaignStart.loading ? "disabled" : "primary"} type="submit" onClick={() => startNewCampaing()}>{newCampaignStart.loading ? <><Loader />&nbsp;START CAMPAIGN</> : "START CAMPAIGN"}</Button> : <></>}
                                    {(balance - budget < 0) ? <Button variation="disabled" >NOT ENOUGH STARS</Button> : <></>}
                                    <br />
                                </Heading>

                                {!balance ? <Button variation="primary" type="submit" onClick={() => navigate("/tasks")}>Go to <b>TASKS</b> to earn <b>STARS</b></Button> : <></>}
                            </>
                    }
                    {hostname.indexOf("1st") > -1 ?
                        <></>
                        :
                        <View align="center"><br />Check video guide <Link href={!!balance ? "https://youtu.be/uYyI9pYpQnM" : "https://youtu.be/ygOvsL7jlcY"} target="_blank">here</Link></View>
                    }

                </View>
            </View>


            <Modal
                show={showModal}
                setShow={setShowModal}
            >
                <ModalHeader>
                    <h2>Your request result:</h2>
                    <p>It may take up to couple of minutes to process your request.</p>
                </ModalHeader>
                <ModalBody>
                    {newCampaignStart.loading ?
                        <><View align="center"><Countdown width="5rem" height="5rem" /></View></>
                        :
                        <p style={{ textAlign: 'justify' }}>
                            {newCampaignStart.response}
                        </p>
                    }
                </ModalBody>
                <ModalFooter>
                    <View align="right">
                        <Button onClick={() => setShowModal(false)}>
                            Close
                        </Button>
                    </View>
                </ModalFooter>
            </Modal>

        </>
    )
}