import { Rating, View } from '@aws-amplify/ui-react';

export function Balance (props) {
    //console.log("Balance props", props);
    return (
        <View style={{whiteSpace: 'nowrap'}}>
            {(("fiatBalance" in props) && (props.fiatBalance !== undefined)) ? "$"+props.fiatBalance : ""}&nbsp;
            {props.balance}
            <Rating
            value={1}
            maxValue={1}
            fillColor="hsl(51, 100%, 50%)"
            size="small"
            />            
        </View>
    )
}