import React, { useEffect, useState } from "react";
import { API, Auth } from "aws-amplify";
import {PerUserPayouts} from './PerUserPayouts';

import {
    Heading,
    Text,
    View,
    useAuthenticator,
    Loader,
} from "@aws-amplify/ui-react";


export function Earnings({ profiles, accessLevel }) {

    const [isLoading, setLoading] = useState(true);
    const [marketingData, setMarketingData] = useState([]);
    const [usersData, setUsersData] = useState([]);
    const [payoutsData, setPayoutsData] = useState([]);
    const { user } = useAuthenticator((context) => [context.user]);
    const jwt = user.signInUserSession.idToken.jwtToken;

    /*
    console.log("user:", user);
    console.log("marketingData:", marketingData);
    console.log("usersData:", usersData);
    console.log("payoutsData:", payoutsData);
    */
    async function getMarketingReport() {
        var userLocal = undefined;
        try {
            userLocal = await Auth.currentAuthenticatedUser();
        } catch (error) {
            userLocal = user;
        }
        //when just signed out both user & userLocal are undefined
        try {
            const requestReport = {
                headers: {
                    Authorization: `${userLocal.signInUserSession.idToken.jwtToken}`,
                },
                body: {
                    accessLevel: accessLevel,
                    profileUrl: profiles ? profiles[0].SK : "",
                },
            };
            const data = await API.post(
                "gcAPI",
                `/dashboard-payouts-report`,
                requestReport
            );
            //console.log("data:", data);

            //var returnResponse = data.Data;

            //console.log("returnResponse:", returnResponse);

            //inside data.Payouts there is array of - sort by each payout SK (it has USER PAYOUT 1234567890) save it as new payoutsToWork

            await setMarketingData(data.Data);
            await setPayoutsData(data.Payouts);
            await setUsersData(data.Users);
            setLoading(false);
        } catch (e) {
            console.log("problem calling api", e);
        }
    }

    function numberCompleted(objByUuid) {
        let count = 0;
        //go over each profile and count tasks

        Object.values(objByUuid).forEach((tasks) => {
            count += tasks.length;
        } );
        
        return count;
    }

    useEffect(() => {
        //call API to get marketing report data
        if (marketingData.length < 1) getMarketingReport();
        //eslint-disable-next-line
    }, []);

    //console.log("profiles[0].SK:", profiles[0].SK); //===tasks[0].uuid

    //create new list of uniq profiles by simialr uuid
    let uniqProfiles = [];
    if (marketingData) {
        //by uuid - group marketingData
        uniqProfiles = marketingData.reduce((acc, item) => {
            if (!acc[item.uuid]) {
                acc[item.uuid] = [];
            }
            //if item.payout matching record in payoutsData[user.username] array by SK then add it to the item is comment does not contain 'ARCHIVE'
            if ("payout" in item) {
            if (payoutsData && payoutsData[user.username]) {
                let found = false;
                for (let payout of payoutsData[user.username]) {
                    if (payout.SK === item.payout && payout.comment?.includes("ARCHIVE")) {
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    acc[item.uuid].push(item);
                }
            }
            } else {
                acc[item.uuid].push(item);
            }
            return acc;
        }, {});
    } else {
        console.log("no marketing data");
    }

    ///console.log("uniqProfiles:", uniqProfiles);
    //console.log("uniqProfiles.length:", Object.keys(uniqProfiles).length);

    //get all tasks for all campaigns/users etc which have no payout key and are completed etc.
    //count those tasks quantity as 1 if requestType is PAID and 10 if PAID-REPOST
    //add up all the tasks for all users

    let notRequestedPayouts = 0;
    let requestedPayouts = 0;
    let sentPayouts = 0;

    if (marketingData?.length) {
        marketingData.forEach((task) => {
            if (task.payout) {
            } else {
                if (task.requestType === "PAID") notRequestedPayouts += task.quantity;
                if (task.requestType === "PAID-REPOST")
                    notRequestedPayouts += task.quantity * 10;
            }
        });
    }
        //go over each payout and count sumRequested and sumPaid        

        /* paoutsData example

{
    "USER PROFILE https://www.linkedin.com/in/serhiy-khvashchuk/": [
        {
            "lastUpdated": "1727091967",
            "stage": "sent",
            "tasks": [
                {
                    "msg": "comment not validated;",
                    "profileUrl": "https://www.linkedin.com/in/serhiy-khvashchuk",
                    "postUrl": "https://www.linkedin.com/feed/update/urn:li:activity:7100478492483452929",
                    "quantity": 5,
                    "requestType": "PAID",
                    "avatarUrl": "1692888314159-avatar.png",
                    "like": "Like",
                    "needsIntro": false,
                    "needsComment": true,
                    "RequestTime": "2023-08-28T19:23:54.221Z",
                    "uuid": "USER PROFILE https://www.linkedin.com/in/serhiy-khvashchuk/",
                    "followers": "15878",
                    "stage": "completed",
                    "validated": true,
                    "balance": 0,
                    "authorUser": "4b640b8e-e7b9-4fd3-8748-84b93449b244",
                    "fsd_profile": "ACoAADxmt4MBxKQwPSe0jcX1HqHNC-UDBegJP94",
                    "intro": "",
                    "SK": "TASK CAMPAIGN https://www.linkedin.com/feed/update/urn:li:activity:7100478492483452929 2023-08-24T14:41:44.445Z",
                    "profileOwner": "d0b2e5ef-604a-4d92-99bd-56c4f9f983f6",
                    "comment": "An incredibly important reminder of why safety protocols and understanding the signs of heatstroke are so crucial in the workplace. Let's all strive to ensure worker welfare is always a priority. No job is worth risking a life for. Stay safe everyone!",
                    "attempted": true
                },
                {
                    "msg": "comment not validated;",
                    "profileUrl": "https://www.linkedin.com/in/serhiy-khvashchuk",
                    "postUrl": "https://www.linkedin.com/posts/annette-mccanney-10bb64223_bullying-bully-workplace-activity-7086750529984323584-3EY2",
                    "quantity": 5,
                    "requestType": "PAID",
                    "avatarUrl": "1689619336635-avatar.png",
                    "like": "Like",
                    "needsIntro": false,
                    "needsComment": true,
                    "RequestTime": "2023-08-14T21:05:40.600Z",
                    "uuid": "USER PROFILE https://www.linkedin.com/in/serhiy-khvashchuk/",
                    "followers": 7009,
                    "stage": "completed",
                    "validated": true,
                    "balance": 0,
                    "authorUser": "4b640b8e-e7b9-4fd3-8748-84b93449b244",
                    "fsd_profile": "ACoAADxmt4MBxKQwPSe0jcX1HqHNC-UDBegJP94",
                    "intro": "",
                    "SK": "TASK CAMPAIGN https://www.linkedin.com/posts/annette-mccanney-10bb64223_bullying-bully-workplace-activity-7086750529984323584-3EY2 2023-07-17T18:42:04.268Z",
                    "requestIPCountry": "none",
                    "profileOwner": "d0b2e5ef-604a-4d92-99bd-56c4f9f983f6",
                    "comment": "",
                    "attempted": true
                },
                {
                    "profileUrl": "https://www.linkedin.com/in/serhiy-khvashchuk",
                    "postUrl": "https://www.linkedin.com/posts/annette-mccanney-10bb64223_the-cost-of-trying-to-force-a-staffer-with-activity-7092914589872349185-mJMC",
                    "quantity": 1,
                    "requestType": "PAID",
                    "avatarUrl": "1691086685288-avatar.png",
                    "like": "Like",
                    "needsIntro": false,
                    "needsComment": false,
                    "RequestTime": "2023-08-14T15:28:36.729Z",
                    "uuid": "USER PROFILE https://www.linkedin.com/in/serhiy-khvashchuk/",
                    "followers": 7234,
                    "stage": "completed",
                    "validated": true,
                    "balance": 0,
                    "authorUser": "4b640b8e-e7b9-4fd3-8748-84b93449b244",
                    "fsd_profile": "ACoAADxmt4MBxKQwPSe0jcX1HqHNC-UDBegJP94",
                    "intro": "",
                    "SK": "TASK CAMPAIGN https://www.linkedin.com/posts/annette-mccanney-10bb64223_the-cost-of-trying-to-force-a-staffer-with-activity-7092914589872349185-mJMC 2023-08-03T18:17:48.804Z",
                    "requestIPCountry": "none",
                    "profileOwner": "d0b2e5ef-604a-4d92-99bd-56c4f9f983f6",
                    "comment": "",
                    "attempted": true
                },
                {
                    "msg": "comment not validated;",
                    "profileUrl": "https://www.linkedin.com/in/serhiy-khvashchuk",
                    "postUrl": "https://www.linkedin.com/posts/fiona-mccanney_heat-summerheat-deaths-activity-7087126799754645504-l48t",
                    "quantity": 5,
                    "requestType": "PAID",
                    "avatarUrl": "1689706037920-avatar.png",
                    "like": "Celebrate",
                    "needsIntro": false,
                    "needsComment": true,
                    "RequestTime": "2023-08-14T21:05:25.322Z",
                    "uuid": "USER PROFILE https://www.linkedin.com/in/serhiy-khvashchuk/",
                    "followers": 15480,
                    "stage": "completed",
                    "validated": true,
                    "balance": 0,
                    "authorUser": "4b640b8e-e7b9-4fd3-8748-84b93449b244",
                    "fsd_profile": "ACoAADxmt4MBxKQwPSe0jcX1HqHNC-UDBegJP94",
                    "intro": "",
                    "SK": "TASK CAMPAIGN https://www.linkedin.com/posts/fiona-mccanney_heat-summerheat-deaths-activity-7087126799754645504-l48t 2023-07-18T18:47:01.946Z",
                    "requestIPCountry": "none",
                    "profileOwner": "d0b2e5ef-604a-4d92-99bd-56c4f9f983f6",
                    "comment": "Wow, what a heartbreaking story. It just goes to show how important it is to stay safe and hydrated, even at work. All employers must prioritize the welfare of their staff, as healthy employees make a healthy company. Let's always remember to take care of each other. #SafetyFirst #StayHydrated",
                    "attempted": true
                },
                {
                    "msg": "comment not validated;",
                    "profileUrl": "https://www.linkedin.com/in/serhiy-khvashchuk",
                    "postUrl": "https://www.linkedin.com/posts/fiona-mccanney_teenager-pulled-into-stacking-machine-suffers-activity-7087819348135854081-bdOU",
                    "quantity": 5,
                    "requestType": "PAID",
                    "avatarUrl": "1689869997344-avatar.png",
                    "like": "Insightful",
                    "needsIntro": false,
                    "needsComment": true,
                    "RequestTime": "2023-08-14T21:05:49.519Z",
                    "uuid": "USER PROFILE https://www.linkedin.com/in/serhiy-khvashchuk/",
                    "followers": 15505,
                    "stage": "completed",
                    "validated": true,
                    "balance": 0,
                    "authorUser": "4b640b8e-e7b9-4fd3-8748-84b93449b244",
                    "fsd_profile": "ACoAADxmt4MBxKQwPSe0jcX1HqHNC-UDBegJP94",
                    "intro": "",
                    "SK": "TASK CAMPAIGN https://www.linkedin.com/posts/fiona-mccanney_teenager-pulled-into-stacking-machine-suffers-activity-7087819348135854081-bdOU 2023-07-20T16:19:40.838Z",
                    "requestIPCountry": "none",
                    "profileOwner": "d0b2e5ef-604a-4d92-99bd-56c4f9f983f6",
                    "comment": "",
                    "attempted": true
                },
                {
                    "msg": "comment not validated;",
                    "profileUrl": "https://www.linkedin.com/in/serhiy-khvashchuk",
                    "postUrl": "https://www.linkedin.com/posts/francisjuarez_want-to-know-my-recommendation-for-the-week-activity-7091152333677490178-gYG2",
                    "quantity": 5,
                    "requestType": "PAID",
                    "avatarUrl": "1690662605191-avatar.png",
                    "like": "Celebrate",
                    "needsIntro": false,
                    "needsComment": true,
                    "RequestTime": "2023-08-14T15:28:17.849Z",
                    "uuid": "USER PROFILE https://www.linkedin.com/in/serhiy-khvashchuk/",
                    "followers": 3630,
                    "stage": "completed",
                    "validated": true,
                    "balance": 0,
                    "authorUser": "590e4997-ca12-4963-9456-2b78a666b7a0",
                    "fsd_profile": "ACoAADxmt4MBxKQwPSe0jcX1HqHNC-UDBegJP94",
                    "intro": "",
                    "SK": "TASK CAMPAIGN https://www.linkedin.com/posts/francisjuarez_want-to-know-my-recommendation-for-the-week-activity-7091152333677490178-gYG2 2023-07-29T20:29:45.490Z",
                    "requestIPCountry": "none",
                    "profileOwner": "d0b2e5ef-604a-4d92-99bd-56c4f9f983f6",
                    "comment": "\"Picture this, my curious little mind: Imagine playing an epic game of 'Hot Lava' in the desert, with fancy race cars and a whole team of superheroes braving the heat! And just like in any great game, everyone has a special role. Some are like wizards conjuring shade and cool breezes, others are like elves preparing magical cooling wraps. There's als,o a pair of expert photographers capturing the adventure from different angles. And of course, every hero needs a trusty sidekick – that's where Joshua and Angie come in. Oh, and let's not forget the director, E'an, who's like the game master, guiding us through one of the most exciting levels we've played in a long time!\"",
                    "attempted": true
                }
            ],
            "uuid": "d0b2e5ef-604a-4d92-99bd-56c4f9f983f6",
            "comment": "old",
            "SK": "USER PAYOUT 1727090372",
            "payoutDestinationType": "stripe",
            "sumPaid": "22.10",
            "sumRequested": 26,
            "payoutDestination": "s@d.cm"
        },
        {
            "lastUpdated": "1727090438",
            "stage": "sent",
            "tasks": [
                {
                    "msg": "comment not validated;",
                    "profileUrl": "https://www.linkedin.com/in/serhiy-khvashchuk",
                    "postUrl": "https://www.linkedin.com/feed/update/urn:li:activity:7096133264217624576",
                    "quantity": 5,
                    "requestType": "PAID",
                    "avatarUrl": "1694984130301-avatar.png",
                    "like": "Celebrate",
                    "needsIntro": false,
                    "needsComment": true,
                    "payout": "USER PAYOUT 1726949367",
                    "RequestTime": "2023-09-18T17:46:49.964Z",
                    "uuid": "USER PROFILE https://www.linkedin.com/in/serhiy-khvashchuk/",
                    "stage": "completed",
                    "validated": true,
                    "balance": 0,
                    "authorUser": "a916e097-0293-444a-9d1b-0f7526969c58",
                    "fsd_profile": "ACoAADxmt4MBxKQwPSe0jcX1HqHNC-UDBegJP94",
                    "intro": "",
                    "SK": "TASK CAMPAIGN https://www.linkedin.com/feed/update/urn:li:activity:7096133264217624576 2023-09-17T20:54:47Z",
                    "profileOwner": "d0b2e5ef-604a-4d92-99bd-56c4f9f983f6",
                    "comment": "Unlocking true potential! 👏🔑",
                    "attempted": true
                }
            ],
            "uuid": "d0b2e5ef-604a-4d92-99bd-56c4f9f983f6",
            "comment": "old",
            "SK": "USER PAYOUT 1726949367",
            "payoutDestinationType": "stripe",
            "sumPaid": "4.25",
            "sumRequested": 5,
            "payoutDestination": "s@d.cm"
        },
        {
            "lastUpdated": "1726943017",
            "stage": "requested",
            "tasks": [
                {
                    "msg": "comment not validated;",
                    "profileUrl": "https://www.linkedin.com/in/serhiy-khvashchuk",
                    "postUrl": "https://www.linkedin.com/feed/update/urn:li:activity:7087132058698805248",
                    "quantity": 5,
                    "requestType": "PAID",
                    "avatarUrl": "1689706264484-avatar.png",
                    "like": "Like",
                    "needsIntro": false,
                    "needsComment": true,
                    "payout": "USER PAYOUT 1726943017",
                    "RequestTime": "2023-08-14T21:05:34.428Z",
                    "uuid": "USER PROFILE https://www.linkedin.com/in/serhiy-khvashchuk/",
                    "followers": 6787,
                    "stage": "completed",
                    "validated": true,
                    "balance": 0,
                    "authorUser": "4b640b8e-e7b9-4fd3-8748-84b93449b244",
                    "fsd_profile": "ACoAADxmt4MBxKQwPSe0jcX1HqHNC-UDBegJP94",
                    "intro": "",
                    "SK": "TASK CAMPAIGN https://www.linkedin.com/feed/update/urn:li:activity:7087132058698805248 2023-07-18T18:47:30.045Z",
                    "requestIPCountry": "none",
                    "profileOwner": "d0b2e5ef-604a-4d92-99bd-56c4f9f983f6",
                    "comment": "\"In a nutshell, a hardworking man sadly passed away from heat exhaustion at a construction site. His brave daughter is now fighting for justice and changes to make sure something like this doesn't happen again. Stay safe in high temperatures, and remember to hydrate!\"\n\nAlso, here's a friendly reminder that even adults need to take breaks and drink plenty of wa,ter when it's hot outside. Let's all look out for our colleagues, especially in these sweltering summer months.",
                    "attempted": true
                },
                {
                    "profileUrl": "https://www.linkedin.com/in/serhiy-khvashchuk",
                    "postUrl": "https://www.linkedin.com/feed/update/urn:li:activity:7092128112863268864/",
                    "quantity": 5,
                    "requestType": "PAID",
                    "avatarUrl": "1690901211468-avatar.png",
                    "like": "Support",
                    "needsIntro": false,
                    "needsComment": true,
                    "payout": "USER PAYOUT 1726943017",
                    "RequestTime": "2023-08-14T13:01:11.934Z",
                    "uuid": "USER PROFILE https://www.linkedin.com/in/serhiy-khvashchuk/",
                    "followers": 9218,
                    "stage": "completed",
                    "validated": true,
                    "balance": 0,
                    "authorUser": "37546459-6035-4bd1-a10e-22b8a8b421ae",
                    "fsd_profile": "ACoAADxmt4MBxKQwPSe0jcX1HqHNC-UDBegJP94",
                    "intro": "",
                    "SK": "TASK CAMPAIGN https://www.linkedin.com/feed/update/urn:li:activity:7092128112863268864/ 2023-08-01T14:46:33.837Z",
                    "requestIPCountry": "none",
                    "profileOwner": "d0b2e5ef-604a-4d92-99bd-56c4f9f983f6",
                    "comment": "Big car companies are starting to sell new types of fuel for cars. They are also changing what they offer with their cars, like special charging bundles for electric cars. Here's how many new cars and special fuel cars they had for sale:\n\n- AutoNation had 41,623 cars, with 7,962 special fuel cars (19.1%)\n- Lithia & Driveway had 39,970 cars, with 3,320 special ,fuel cars (8.4%)\n- Hendrick Automotive Group had 18,182 cars, with 6,050 special fuel cars (33.2%)\n- Sonic Automotive had 11,749 cars, with 2,173 special fuel cars (18.5%)\n- Asbury Automotive Group had 10,487 cars, with 2,269 special fuel cars (21.6%)\n- And so on. \n\nAlso, more than half of customers are buying the special charging bundles for their cars, which is making the car companies extra money.",
                    "attempted": true
                }
            ],
            "uuid": "d0b2e5ef-604a-4d92-99bd-56c4f9f983f6",
            "SK": "USER PAYOUT 1726943017",
            "payoutDestinationType": "stripe",
            "sumPaid": "8.50",
            "sumRequested": 10,
            "payoutDestination": "s@d.cm"
        }
    ],
    "USER PROFILE https://www.linkedin.com/in/cristanto/": [],
    "USER PROFILE https://www.linkedin.com/in/ralph-gordon-25648a213/": [],
    "USER PROFILE https://www.linkedin.com/in/katelynmasek/": [],
    "USER PROFILE https://www.linkedin.com/in/bart-daniels-42b57720b": [],
    "USER PROFILE https://www.linkedin.com/in/latoyarj/": [],
    "USER PROFILE https://www.linkedin.com/in/kat-m-018852224": [],
    "USER PROFILE https://www.linkedin.com/in/johnrcasson": [],
    "USER PROFILE https://www.linkedin.com/in/julia-khvashchuk-99a668169/": [],
    "USER PROFILE https://www.linkedin.com/in/ralph-gordon-25648a213": [],
    "USER PROFILE https://www.linkedin.com/in/thomas-daniels-42b57720b": [],
    "USER PROFILE https://www.linkedin.com/in/crystal-carlson-mep": [],
    "USER PROFILE https://www.linkedin.com/in/curtisduffin": [],
    "USER PROFILE https://www.linkedin.com/in/membershipspecialist": [],
    "USER PROFILE https://www.linkedin.com/in/halliekilavos/": [],
    "USER PROFILE https://www.linkedin.com/in/haladean/": [],
    "USER PROFILE https://www.linkedin.com/in/soheilghahremani": [],
    "USER PROFILE https://www.linkedin.com/in/annette-mccanney-10bb64223/": [],
    "USER PROFILE https://www.linkedin.com/in/fiona-mccanney/": [],
    "USER PROFILE https://www.linkedin.com/in/jackie-de-burca-33776211/": [],
    "USER PROFILE https://www.linkedin.com/in/james-edward-jr-96007b211/": []
}

        */

    if (payoutsData && Object.keys(payoutsData).length > 0) {
        Object.values(payoutsData).forEach((payoutArray) => {
            payoutArray.forEach((payout) => {
                if ((payout.stage === "requested") &&
                    !(payout.comment?.includes("ARCHIVE"))) {
                requestedPayouts += Number(payout.sumPaid);
                };
                if ((payout.stage === "sent") &&
                    (payout.comment.includes("ARCHIVE") === false)) {
                sentPayouts += Number(payout.sumPaid);
                };
            });
        });
    }

    return (
        <View align="center">
            <Heading level={3} fontWeight={"bold"} textAlign="center">
                Earnings Report
            </Heading>
            <View align="center">
                <Text variation="secondary">
                    <br />
                    {uniqProfiles ? numberCompleted(uniqProfiles) : ""} PAID
                    tasks for{" "}
                    {uniqProfiles ? Object.keys(uniqProfiles).length : ""}{" "}
                    profiles
                    <br /><br />
                    Not requested payouts: ${notRequestedPayouts.toFixed(2)}<br />
                    Requested payouts: ${requestedPayouts.toFixed(2)}<br />
                    Paid payouts: ${sentPayouts.toFixed(2)}<br />
                </Text>
            </View>
            <br />
            <br />
            {isLoading ? (
                <Loader variation="linear" />
            ) : (
                Object.keys(uniqProfiles).map((uuid, index) => (
                    <View key={index}>
                        <PerUserPayouts
                            tasks={uniqProfiles[uuid]}
                            profiles={usersData[user.username]}
                            payouts={payoutsData[user.username]}
                            jwt={jwt}
                            accessLevel={accessLevel}
                        />
                        <br />
                    </View>
                ))
            )}
        </View>
    );
}
