import { useEffect, useState } from 'react';
import { API, Auth } from 'aws-amplify';

import {
  Button,
  Heading,
  Flex,
  Text,
  View,
  SwitchField,
  TextAreaField,
  TextField,
  Collection,
  Loader,
  Link,
  Expander,
  ExpanderItem,
  Tabs,
  TabItem
} from '@aws-amplify/ui-react';

import { useNavigate } from "react-router-dom";

export function SpecificProfile(props) {
  const navigate = useNavigate();
  const [saveLoading, setSaveLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [connections, setConnections] = useState([]);
  const [profileSettings, setProfileSettings] = useState({});
  const [isMobile] = useState(/iphone|ipad/i.test(navigator.userAgent));

  async function getProfile() {

    setIsLoading(true);

    const user = await Auth.currentAuthenticatedUser();
    var getProfileParams = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}`
      },
      queryStringParameters: {
        profileId: window.location.pathname.split('settings/').filter(Boolean).pop()
      }
    };

    try {

      var data = await API.get("gcAPI", `/get-profile/${user.username}`, getProfileParams);
      //console.log('getProfileParams: ', getProfileParams);
      //console.log('getProfile success: ', data);
      setConnections(data.connections);
      setProfileSettings(data.profile);
      //setProfile(data);
      //setProfileLoading(false);
    } catch (e) {
      console.log('getProfileParams: ', getProfileParams);
      console.log('getProfile error: ', e.message);
      //setProfileLoading(false);
    };

    setIsLoading(false);
  }

  async function updateProfile() {
    setSaveLoading(true);
    const user = await Auth.currentAuthenticatedUser();
    var updateProfileParams = {
      headers: {
        Authorization: `${user.signInUserSession.idToken.jwtToken}`
      },
      body: {
        profileId: window.location.pathname.split('settings/').filter(Boolean).pop(),
      }
    };

    if (document.getElementById("notificationsPaid")) {
      updateProfileParams.body.notificationsPaid = document.getElementById("notificationsPaid").checked;
    }

    if (document.getElementById("email")) {
      updateProfileParams.body.email = document.getElementById("email").value;
    }

    if (document.getElementById("autoconnect")) {
      updateProfileParams.body.autoconnect = document.getElementById("autoconnect").checked;
    }

    if (document.getElementById("connections")) {
      updateProfileParams.body.connections = document.getElementById("connections").value;
    }

    if (document.getElementById("limit")) {
      updateProfileParams.body.limit = document.getElementById("limit").value;
    }

    if (document.getElementById("pods")) {
      updateProfileParams.body.pods = document.getElementById("pods").value;
    }

    if (document.getElementById("companies")) {
      updateProfileParams.body.companies = document.getElementById("companies").value;
    }

    if (document.getElementById("keywords")) {
      updateProfileParams.body.keywords = document.getElementById("keywords").value;
    }

    try {
      //console.log('updateProfileParams: ', updateProfileParams);
      var data =
        await API.post("gcAPI", `/post-updateProfile/${user.username}`, updateProfileParams);
      //console.log('1st try success: ', data);
      //empty the textarea
      if (document.getElementById("connections")) {
        document.getElementById("connections").value = '';
      };
      if (document.getElementById("companies")) {
        document.getElementById("companies").value = '';
      };
      //tirgger reload of the history or the whole page in order to update history of connect tasks
      if (data.tasksCreated > 0) {
        window.location.reload();
      };
    } catch (e) {
      console.log('initial error: ', e.message);
    };

    getProfile();

    setSaveLoading(false);
  }

  useEffect(() => {
    getProfile();
  }
    , []);

  return (
    <View>
      <Heading level={3} fontWeight={'bold'} textAlign="center">
        Profile Settings
      </Heading>
      <View align="center">
        <Text variation="secondary">
          Change default profile notifications, filters, add connections, and more.
        </Text>
        <br />
        <Button
          onClick={() => { navigate("/settings") }}
          size='small'
        >Back to Settings</Button>
      </View>
      <br />
      <br />
      <Tabs spacing="equal">

        <TabItem title={isMobile ? "Grow" : "Grow"}>
          <><br />
            {profileSettings.SK && profileSettings.SK.indexOf('/in/') < 0 ? <Heading level={4} fontWeight={'bold'} textAlign="center">
              Company or School profiles can't connect with other profiles
            </Heading> : <>
              <Flex as="form" direction="column" width="100%" id="1">
                <TextAreaField
                  descriptiveText="Add one company name per line."
                  label={<><b>Companies to connect with</b> {isLoading ? <></> : <>(you have {(100 - ("companiesWeeklyLimitCounter" in profileSettings ? profileSettings.companiesWeeklyLimitCounter : 0)) ? (100 - ("companiesWeeklyLimitCounter" in profileSettings ? profileSettings.companiesWeeklyLimitCounter : 0)) < 0 ? 0 : (100 - ("companiesWeeklyLimitCounter" in profileSettings ? profileSettings.companiesWeeklyLimitCounter : 0)) : 0} to use by next Monday)</>}</>}
                  placeholder={`Great Brand\nAnother Brand\nThird Brand`}
                  rows={5}
                  id="companies"
                />
                {isLoading ? <Loader variation="linear" /> : <TextField
                  label={<b>Keywords to search profiles for each company</b>}
                  defaultValue={profileSettings.keywords ? profileSettings.keywords : 'founder OR CEO OR OR president OR director OR VP OR HR OR marketing'}
                  placeholder={"keywords" in profileSettings ? '' : 'loading...'}
                  rows={5}
                  id="keywords"
                  type='text'
                  onChange={(e) => {
                    setProfileSettings({ ...profileSettings, keywords: e.target.value });
                  }}
                />}
                <Expander
                  label={<span dangerouslySetInnerHTML={{ __html: 'How to use <b>Companies to connect with</b> guide' }} />}
                  isCollapsible={true}
                  maxWidth="vw"
                >
                  <ExpanderItem title={<span dangerouslySetInnerHTML={{ __html: 'How to use <b>Companies to connect with</b> guide' }} />}
                    maxWidth="100%"
                    value='options'
                  >
                    <ul>
                      <li>If not empty we immediately transform this list to CONNECT tasks</li>
                      <li>Write one company or brand name per line</li>
                      <li>You may add as many company or brand names at once</li>
                      <li>Write keywords to search profiles for each company</li>
                      <li>We save keywords for your future use</li>
                      <li>Perform CONNECT tasks among all other tasks</li>
                      <li>Every CONNECT task will resuface in 2 weeks to be confirmed or withdrawn</li>
                      <li>Grow your network to thousands of connectins spending just minutes daily</li>
                      <li>Check every connection status in the Connections History below</li>
                    </ul>
                  </ExpanderItem>
                </Expander>
                <br />
                <TextAreaField
                  descriptiveText="Add one profile URL per line."
                  label={<b>Profiles to connect with</b>}
                  placeholder="https://www.linkedin.com/in/serhiy-khvashchuk/"
                  rows={5}
                  id="connections"
                />
                {/*
                <br />
                <br />
                <TextField
                  descriptiveText="Enter a number of connect requests to send per 7 days. Current LinkedIn limit is 100-150. We recommend to set it to 100"
                  placeholder="100"
                  value={100}
                  label="7 day limit"
                  type="number"
                  errorMessage="Please enter a valid number"
                  id='limit'
                  isRequired={true}
                  onChange={() => { }}
                />*/}
                <Expander
                  label={<span dangerouslySetInnerHTML={{ __html: 'How to use <b>Profiles to connect with</b> guide' }} />}
                  isCollapsible={true}
                  maxWidth="vw"
                >
                  <ExpanderItem title={<span dangerouslySetInnerHTML={{ __html: 'How to use <b>Profiles to connect with</b> guide' }} />}
                    maxWidth="100%"
                    value='options'
                  >
                    <ul>
                      <li>If not empty we immediately transform this list to CONNECT tasks</li>
                      <li>Write one LinkedIn profile URL per line</li>
                      <li>You may add many profile URLs as you want at once</li>
                      <li>Perform CONNECT tasks among all other tasks</li>
                      <li>Every CONNECT task will resuface in 2 weeks to be confirmed or withdrawn</li>
                      <li>Grow your network to thousands of connectins spending just minutes daily</li>
                      <li>Check every connection status in the Connections History below</li>
                    </ul>
                  </ExpanderItem>
                </Expander>
                <br />
                {isLoading ? <Loader variation="linear" /> :
                  <SwitchField
                    isDisabled={false}
                    label={<b>Autoconnect</b>}
                    labelPosition="start"
                    defaultChecked={profileSettings.autoconnect}
                    id="autoconnect"
                  />}
                <Text variation="secondary">
                  Autoconnect means our system will suggest the profiles <br />in case the <b>Companies to connect with</b> and / or <b>Profiles to connect with</b> inputs are empty or processed.
                </Text>
                <br />
                <br />
                <Button
                  onClick={() => updateProfile()}
                  size='large'
                  variation="primary"
                  isLoading={saveLoading}
                >SAVE</Button>
                <br />
                <br />
                <br />
                <br />
                <Heading level={3} fontWeight={'bold'} textAlign="center">
                  Connections History
                </Heading>
                <View align="center">
                  <Text variation="secondary">
                    List of added connections with statuses (new/pending/connected/withdrawn) and date sorted by date and with pagination and search like in users list
                  </Text>
                  <Flex>
                    {connections.length > 0 ? <Text variation="secondary">
                      Total: {connections.length}
                    </Text> : <></>}
                    {connections.filter(item => item.stage === 'completed').length > 0 ? <Text variation="primary">
                      Connected: {connections.filter(item => item.stage === 'completed').length}
                    </Text> : <></>}
                    {connections.filter(item => item.stage === 'pending').length > 0 ? <Text variation="warning">
                      Pending: {connections.filter(item => item.stage === 'pending').length}
                    </Text> : <></>}
                    {//show number of new / pending / completed / rejected connections
                      connections.filter(item => item.stage === 'new').length > 0 ? <Text variation="success">
                        New: {connections.filter(item => item.stage === 'new').length}
                      </Text> : <></>}
                    {connections.filter(item => item.stage === 'rejected').length > 0 ? <Text variation="danger">
                      Withdrawn: {connections.filter(item => item.stage === 'rejected').length}
                    </Text> : <></>
                    }
                  </Flex>
                  <br />
                  <Button
                    onClick={() => { navigate("/settings") }}
                    size='small'
                  >Back to Settings</Button>
                </View>
                <br />
                <br />
                {isLoading ? <Loader variation="linear" /> : <Collection
                  direction='column'
                  type='list'
                  items={connections}
                  isPaginated
                  itemsPerPage={50}
                  isSearchable
                  searchPlaceholder="Type to search..."
                >
                  {(item, index) => (
                    <View
                      key={index}
                      padding="0rem"
                    >
                      <Text
                        variation={item.stage === 'new' ? 'success' : item.stage === 'pending' ? 'warning' : item.stage === 'connected' ? 'primary' : 'danger'}
                        fontWeight={'bold'}
                      >
                        {item.stage}
                      </Text>
                      <Text>
                        {item.RequestTime}
                      </Text>
                      <Link
                        href={item.postUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.postUrl}
                      </Link>
                      <Text>
                        {item.title}
                      </Text>
                      <Text>
                        {item.company}
                      </Text>
                      <Text>
                        {item.countryCode}
                      </Text>
                      {/*<Text>
                        {item.description}
                  </Text>*/}

                    </View>
                  )}
                </Collection>}
              </Flex>
            </>}
          </>
        </TabItem>

        <TabItem title={isMobile ? "Notifications" : "Notifications"}>
          <><br />
            {profileSettings.SK && profileSettings.SK.indexOf('/in/') < 0 ? <Heading level={4} fontWeight={'bold'} textAlign="center">
              Company or School profiles can't connect with other profiles
            </Heading> : <>
              <Flex as="form" direction="column" width="100%" id="2">
                {isLoading ? <Loader variation="linear" /> : <>
                  <SwitchField
                    isDisabled={false}
                    label={<b>Notifications About Paid Tasks</b>}
                    labelPosition="start"
                    defaultChecked={profileSettings.notificationsPaid}
                    id="notificationsPaid"
                  />
                  <Text variation="secondary">
                    Notifications About Paid Tasks ON means our system will send you and email <br />every time new <b>PAID</b> task available but no more then once in an hour.
                  </Text>
                  <br />
                  <TextField
                    descriptiveText="You may set different email for each profile"
                    disabled={true}
                    label={<b>Email for PAID tasks notifications</b>}
                    defaultValue={profileSettings.email ? profileSettings.email : ''}
                    placeholder={"email@some.com" in profileSettings ? '' : 'loading...'}
                    rows={5}
                    id="email"
                    type='email'
                    onChange={(e) => {
                      setProfileSettings({ ...profileSettings, email: e.target.value });
                    }}
                  />
                  <Expander
                    label="How Notifications About Paid Tasks Work"
                    isCollapsible={true}
                    maxWidth="vw"
                  >
                    <ExpanderItem title={"How to use notifications about PAID tasks guide"}
                      maxWidth="100%"
                      value='options'
                    >
                      <ul>
                        <li>Notifications are sent out no often the once in hour</li>
                        <li>First we notify people from the same country as the campaign</li>
                        <li>Then we send notifications to those with biggest followers base</li>
                        <li>If campaign is for the group - only group members get notifications</li>
                        <li>By default notifications are ON</li>
                        <li>If you don't want to receive notifications - turn them OFF or unsubscribe from the email</li>
                        <li>For now you can't change email as it must be validated - soon to be updated</li>
                        <li>Each profile may have different email for the notifications - soon to be updated</li>
                      </ul>
                    </ExpanderItem>
                  </Expander>
                  <br />
                  <br />
                  <Button
                    onClick={() => updateProfile()}
                    size='large'
                    variation="primary"
                    isLoading={saveLoading}
                  >SAVE</Button>
                </>
                }
              </Flex>
            </>}
          </>
        </TabItem>

        <TabItem title={isMobile ? "Groups" : "Groups"}>
          <><br />
            {profileSettings.SK && profileSettings.SK.indexOf('/in/') < 0 ? <Heading level={4} fontWeight={'bold'} textAlign="center">
              Company or School profiles can't connect with other profiles
            </Heading> : <>
              <Flex as="form" direction="column" width="100%" id="3">

                {isLoading ? <Loader variation="linear" /> :
                  <TextAreaField
                    id="pods"
                    value={profileSettings.pods ? profileSettings.pods.split(' ').join('\n') : ''}
                    label={<b>Groups & custom filters</b>}
                    descriptiveText="Add one filter or pod name per line."
                    onChange={(e) => {
                      setProfileSettings({ ...profileSettings, pods: e.target.value });
                    }}
                    placeholder={"pods" in profileSettings ? '' : 'loading...'}
                  />
                }
                <Expander
                  label="How to use groups & custom filters guide"
                  isCollapsible={true}
                  maxWidth="vw"
                >
                  <ExpanderItem title={"How to use groups & custom filters guide"}
                    maxWidth="100%"
                    value='options'
                  >
                    <ul>
                      <li>If not empty for the profile, we show first only campaigns with matching filters</li>
                      <li>Names of the groups and custom filters are the same</li>
                      <li>Write names 1 per line</li>
                      <li>Use easy to copy, meaningful and short names</li>
                      <li>Good examples are one word company/brand names, like Pepsi, Sephora, Mac</li>
                      <li>To easy copy the group/filter name, do tap+hold on mobile or mouse double click on it</li>
                    </ul>
                  </ExpanderItem>
                </Expander>
                <br />
                <br />
                <Button
                  onClick={() => updateProfile()}
                  size='large'
                  variation="primary"
                  isLoading={saveLoading}
                >SAVE</Button>
              </Flex>
            </>}
          </>
        </TabItem>

      </Tabs>

    </View>
  )
}