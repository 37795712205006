import {
  //Link,
  Heading,
  Text,
  View,
  Grid,
  Divider,
  Collection
} from '@aws-amplify/ui-react';
import { MdInfoOutline } from 'react-icons/md'; //https://react-icons.github.io/react-icons
import { useNavigate } from "react-router-dom";


export function Settings(props) {
  const navigate = useNavigate();

  return (
    <View align="center">
      <Heading level={3} fontWeight={'bold'} textAlign="center">
        Settings
      </Heading>
      <View align="center">
        <Text variation="secondary">
          Change default campaign filters, profile settings, and more.
        </Text>
      </View>
      <br />
      <br />
      List of links to profiles pages
      {(props.profiles.length > 0) ?
        <Collection
          direction="column"
          items={props.profiles}
        >
          {(item, index) => (
            <View
              key={index}
            >
              <Grid
                columnGap="0.5rem"
                rowGap="0.5rem"
                templateColumns="50px 1fr 50px"
                templateRows="min-content"
                textAlign="left"
                alignItems="center"
                className='profile-button'
                onClick={() => { navigate("/settings/" + item.profileId) }}
              >
                <img src={item.avatarUrl} style={{ borderRadius: '50%', maxWidth: '50px' }} alt={item.name} />
                {(props.aProfile.profileId === item.profileId) ?
                  <Text className='active-profile'>
                    {item.name}
                  </Text>
                  :
                  <Text isTruncated={true}>
                    {item.name}
                  </Text>
                }
                {item.validated ? <></> : <MdInfoOutline title="Profile validation isn't complete!" />}
              </Grid>
              <br />
              <Divider />
            </View>
          )}
        </Collection>
        : <></>}
    </View>
  )
}