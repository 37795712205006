import { useEffect, useState, useRef } from 'react';
import { API, Auth } from 'aws-amplify';
import {
  Heading,
  Text,
  View,
  useAuthenticator,
  Loader,
} from '@aws-amplify/ui-react';

export function UsersReport({ updateProfiles, aProfile, updateAProfile }) {
  const [isLoading, setLoading] = useState(true);
  const [marketingData, setMarketingData] = useState([]);
  const { user } = useAuthenticator((context) => [context.user]);
  const usersTableRef = useRef(null);

  async function getMarketingReport() {
    var userLocal = undefined;
    try {
      userLocal = await Auth.currentAuthenticatedUser();
    } catch (error) {
      userLocal = user;
    }
    try {
      const requestReport = {
        headers: {
          Authorization: `${userLocal.signInUserSession.idToken.jwtToken}`
        }
      };
      const data = await API.get("gcAPI", `/dashboard-users-report`, requestReport);
      setMarketingData(data.Data);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    const scripts = [
      //'./js/vendor/jquery-3.2.1.min.js',
      'https://cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js',
      'https://cdn.datatables.net/1.11.0/js/jquery.dataTables.js',
      'https://cdn.datatables.net/buttons/2.0.0/js/dataTables.buttons.min.js',
      'https://cdn.datatables.net/buttons/2.0.0/js/buttons.html5.min.js'
    ];

    const loadScript = (src) => {
      return new Promise((resolve, reject) => {
        if (document.querySelector(`script[src="${src}"]`)) {
          resolve();
          return;
        }
        const script = document.createElement('script');
        script.src = src;
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild(script);
      });
    };

    const loadScripts = async () => {
      try {
        for (let i = 0; i < scripts.length; i++) {
          await loadScript(scripts[i]);
        }
        getMarketingReport();
      } catch (error) {
        console.error('Error loading script:', error);
      }
    };

    loadScripts();

    return () => {
      scripts.forEach((src) => {
        const script = document.querySelector(`script[src="${src}"]`);
        if (script) {
          document.body.removeChild(script);
        }
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (marketingData.length > 0) {
      if (usersTableRef.current) {
        window.$(usersTableRef.current).DataTable({
          dom: 'lBfrtip',
          buttons: ['csv'],
          paging: false,
          order: [[4, 'desc']],
        });
      }
    }
  }, [marketingData]);

  function MarketingReport() {
    const result = marketingData;
    console.log(result);
    const allUsers = result.length;
    const activeUsers = result.filter(user => user.payments === 'active').length;

    return (
      <div>
        <p><b>All users:</b> {allUsers} <b>Payments active:</b> {activeUsers}</p>
        <table ref={usersTableRef} id="usersTable">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Email</th>
              <th>requestIPCountry</th>
              <th>created Date</th>
              <th>Payments</th>
              <th>Stars Balance</th>
              <th>Campaigns Balance</th>
              <th>Fiat Balance</th>
              <th>Lifetime Payouts</th>
              <th>last profile url</th>
              <th>profile campaign last</th>
              <th>uuid</th>
              <th>customerId</th>
              <th>date campaign last</th>
              <th>Request Time</th>
              <th>date upgraded last</th>
              <th>last profile add completed</th>
              <th>headline</th>
              <th>ifPremium</th>
              <th>industry</th>
              <th>location</th>
              <th>name</th>
              <th>state</th>
              <th>validated</th>
              <th>updatedDate</th>
              <th>profileUrl</th>              
            </tr>
          </thead>
          <tbody>
            {marketingData.map((item, index) => (
              <tr key={index}>
                <td>{index}</td>
                <td>{item.name}</td>
                <td>{item.email}</td>
                <td>{item.requestIPCountry}</td>
                <td>{item.createdDate?item.createdDate.split('T').shift():""}</td>
                <td>{item.payments}</td>
                <td>{item.starsBalance}</td>
                <td>{item.postBalance}</td>
                <td>{item.fiatBalance}</td>
                <td>{item.lifetimePayouts}</td>
                <td>{item.last_profile_url ? item.last_profile_url.substring(0, 75) + (item.last_profile_url.length > 75 ? '...' : '') : ''}</td>
                <td>{item.profile_campaign_last ? item.profile_campaign_last.substring(0, 75) + (item.profile_campaign_last.length > 75 ? '...' : '') : ''}</td>
                <td>{item.uuid}</td>
                <td>{item.customerId}</td>
                <td>{item.date_campaign_last}</td>
                <td>{item.RequestTime}</td>
                <td>{item.date_upgraded_last}</td>
                <td>{item.last_profile_add_completed}</td>
                <td>{item.headline}</td>
                <td>{item.ifPremium}</td>
                <td>{item.industry}</td>
                <td>{item.location}</td>
                <td>{item.name}</td>
                <td>{item.state}</td>
                <td>{item.validated}</td>
                <td>{item.updatedDate}</td>
                <td>{item.profileUrl}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
  
  return (
    <View>
      <Heading level={3} fontWeight={'bold'} textAlign="center">
        Users Dashboard
      </Heading>
      <View align="center">
        <Text variation="secondary">
          Search all users for fine-grained analysis
        </Text>
      </View>
      <br />
      <br />
      {isLoading ? <Loader variation="linear" /> : <MarketingReport />}
    </View>
  );
}
