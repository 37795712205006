import { useState } from "react";
import { MdInfoOutline, MdMenu, MdMoreVert } from "react-icons/md"; //https://react-icons.github.io/react-icons
import {
    useAuthenticator,
    View,
    Text,
    Button,
    Grid,
    Card,
    Divider,
    Menu,
    MenuItem,
    MenuButton,
    Collection,
} from "@aws-amplify/ui-react";
import styled, { css } from "styled-components";
import { useNavigate } from "react-router-dom";
import { UserName } from "../UserName";
import { Balance } from "../Balance";

const hostname = window.location.hostname;

const textColor = hostname.includes("1stinfluencer") ? "black" : "white";
const backgroundColor = hostname.includes("1stinfluencer")
    ? "white"
    : "#59687c";

const breakpoints = {
    desktop: "920px",
};

const Backdrop = styled.div`
    position: fixed;
    height: 100vh;
    width: 100vw;
    opacity: 0;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.4);
    transition: opacity 0.2s cubic-bezier(0.4, 0, 1, 1) !important;
    ${(p) =>
        p.visible === "initial" &&
        css`
            opacity: 1;
            pointer-events: all;
        `}
    @media(min-width: ${breakpoints.desktop}) {
        opacity: 0;
        pointer-events: none;
    }
`;

export function Layout(props) {
    /*
    console.log("Layout props", props);
    console.log("Layout props.clientData.fiatBalance",props.clientData.fiatBalance);
    console.log("Layout props.clientData.postBalance",props.clientData.postBalance);
    */
    const VisibilityStates = ["initial", "none"];
    const [showNav, setShowNav] = useState(VisibilityStates[1]);
    const toggle = () =>
        setShowNav(
            VisibilityStates.indexOf(showNav)
                ? VisibilityStates[0]
                : VisibilityStates[1]
        );

    const { user } = useAuthenticator((context) => [context.user]);

    const { signOut } = useAuthenticator((context) => [context.signOut]);

    const navigate = useNavigate();

    function logOut() {
        signOut();
        navigate("/signin");
    }

    function SideMenu() {
        const navigate = useNavigate();
        return (
            <>
                <View width="300px" height="100vh">
                    <Card variation="elevated">
                        <View
                            onClick={() => {
                                props.updateAProfile({});
                                toggle();
                                navigate("/");
                            }}
                        >
                            <UserName
                                balance={props.balance}
                                style={{ color: "black" }}
                                fiatBalance={props.clientData.fiatBalance}
                                postBalance={props.clientData.postBalance}
                            />
                        </View>
                        <br />
                        <b>SOCIAL PROFILES</b>
                        <br />
                        <br />
                        <br />
                        {props.profiles.length > 0 ? (
                            <Collection
                                direction="column"
                                items={props.profiles}
                            >
                                {(item, index) => (
                                    <View key={index}>
                                        <Grid
                                            columnGap="0.5rem"
                                            rowGap="0.5rem"
                                            templateColumns="50px 1fr 50px"
                                            templateRows="min-content"
                                            textAlign="left"
                                            alignItems="center"
                                            className="profile-button"
                                            onClick={() => {
                                                props.updateAProfile(item);
                                                toggle();
                                                navigate("/tasks");
                                            }}
                                        >
                                            <img
                                                src={item.avatarUrl}
                                                style={{
                                                    borderRadius: "50%",
                                                    maxWidth: "50px",
                                                }}
                                                alt={item.name}
                                            />
                                            {props.aProfile.profileId ===
                                            item.profileId ? (
                                                <Text className="active-profile">
                                                    {item.name}
                                                </Text>
                                            ) : (
                                                <Text isTruncated={true}>
                                                    {item.name}
                                                </Text>
                                            )}
                                            {item.validated ? (
                                                <></>
                                            ) : (
                                                <MdInfoOutline title="Profile validation isn't complete!" />
                                            )}
                                        </Grid>
                                        <br />
                                        <Divider />
                                    </View>
                                )}
                            </Collection>
                        ) : (
                            <></>
                        )}
                        <br />
                        <View textAlign="center">
                            <Button
                                variation="primary"
                                onClick={() => {
                                    props.updateAProfile({});
                                    toggle();
                                    if (
                                        window.location.pathname ===
                                        "/addProfil"
                                    ) {
                                        window.location.reload();
                                    } else {
                                        navigate("/addProfile");
                                    }
                                }}
                            >
                                ADD PROFILE
                            </Button>
                        </View>
                    </Card>
                </View>
            </>
        );
    }

    return (
        <Grid
            rowGap="0.5rem"
            templateColumns="min-content 1fr"
            templateRows="min-content 1fr"
        >
            <View
                display={showNav}
                columnStart="1"
                columnEnd="2"
                rowStart="1"
                rowEnd="3"
            >
                <SideMenu />
            </View>

            <View
                columnStart="2"
                columnEnd="3"
                rowStart="1"
                rowEnd="2"
                height="53px"
            >
                <Backdrop visible={showNav} onClick={toggle} />
                <Grid
                    columnGap="0.5rem"
                    rowGap="0.5rem"
                    templateColumns="1fr 50px"
                    templateRows="1fr"
                    z-index="2000"
                    style={{
                        backgroundColor: backgroundColor,
                        color: textColor,
                    }}
                >
                    <View onClick={toggle}>
                        {Object.keys(props.aProfile).length > 0 ? (
                            <Grid
                                columnGap="0.5rem"
                                rowGap="0.5rem"
                                templateColumns="20px 50px 1fr 60px"
                                templateRows="min-content"
                                textAlign="left"
                                alignItems="center"
                                className="profile-button"
                            >
                                <MdMoreVert
                                    size="2.75em"
                                    style={{ marginLeft: "-15px" }}
                                />
                                <img
                                    src={props.aProfile.avatarUrl}
                                    style={{
                                        borderRadius: "50%",
                                        maxWidth: "41px",
                                        marginLeft: "2px",
                                        marginTop: "2px",
                                    }}
                                    alt={props.aProfile.name}
                                />
                                <Text isTruncated={true} color={textColor}>
                                    {props.aProfile.name}
                                    {props.aProfile.validated ? (
                                        <></>
                                    ) : (
                                        <MdInfoOutline
                                            title="Profile validation isn't complete!"
                                            color="red"
                                        />
                                    )}
                                </Text>
                                <Balance
                                    balance={props.balance}
                                    fiatBalance={
                                        props?.clientData?.fiatBalance
                                            ? props.clientData.fiatBalance
                                            : 0
                                    }
                                    postBalance={
                                        props?.clientData?.postBalance
                                            ? props.clientData.postBalance
                                            : 0
                                    }
                                />
                            </Grid>
                        ) : (
                            <Grid
                                columnGap="0.5rem"
                                rowGap="0.5rem"
                                templateColumns="20px 1fr"
                                templateRows="min-content"
                                textAlign="left"
                                alignItems="center"
                                className="profile-button"
                            >
                                <MdMoreVert
                                    size="2.75em"
                                    style={{ marginLeft: "-15px" }}
                                />
                                <UserName
                                    balance={props.balance}
                                    style={{ color: textColor }}
                                    fiatBalance={
                                        props?.clientData?.fiatBalance
                                            ? props.clientData.fiatBalance
                                            : 0
                                    }
                                    postBalance={
                                        props?.clientData?.postBalance
                                            ? props.clientData.postBalance
                                            : 0
                                    }
                                />
                            </Grid>
                        )}
                    </View>
                    <Menu
                        trigger={
                            <MenuButton
                                style={{ color: textColor }}
                                height="41px"
                                size="large"
                                marginTop="2px"
                                marginRight="2px"
                                padding="6px"
                            >
                                {" "}
                                <MdMenu />{" "}
                            </MenuButton>
                        }
                        menuAlign="end"
                        style={{ backgroundColor: backgroundColor }}
                    >
                        <MenuItem
                            onClick={() => {
                                props.updateAProfile({});
                                navigate("/newcampaign");
                            }}
                            style={{ color: textColor }}
                        >
                            New Campaign
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                if (!(Object.keys(props.aProfile).length > 0)) {
                                    if (props.profiles.length > 0) {
                                        props.updateAProfile(props.profiles[0]);
                                    }
                                }
                                navigate("/tasks");
                            }}
                            style={{ color: textColor }}
                        >
                            Tasks
                        </MenuItem>
                        <MenuItem
                            onClick={() => navigate("/history")}
                            style={{ color: textColor }}
                        >
                            History
                        </MenuItem>
                        <Divider />
                        <MenuItem isDisabled>{user.attributes.email}</MenuItem>

                        {props.clientData ? (
                            "postBalance" in props.clientData &&
                            props.clientData.postBalance !== undefined &&
                            ["lite", "pro"].includes(
                                props.clientData.subscriptionPlan
                            ) ? (
                                <MenuItem isDisabled>
                                    Campaigns balance:{" "}
                                    {props.clientData.postBalance}
                                </MenuItem>
                            ) : (
                                <></>
                            )
                        ) : (
                            <></>
                        )}
                        {"subscriptionPlan" in props.clientData &&
                        props.clientData.subscriptionPlan !== undefined &&
                        props.clientData.subscriptionPlan !== "none" ? (
                            <MenuItem isDisabled>
                                Plan name: {props.clientData.subscriptionPlan}
                            </MenuItem>
                        ) : (
                            <></>
                        )}

                        <MenuItem
                            onClick={() => navigate("/earnings")}
                            style={{ color: textColor }}
                        >
                            Earnings
                        </MenuItem>
                        <MenuItem
                            onClick={() => navigate("/billing")}
                            style={{ color: textColor }}
                        >
                            Billing
                        </MenuItem>
                        <MenuItem
                            onClick={() => navigate("/settings")}
                            style={{ color: textColor }}
                        >
                            Settings
                        </MenuItem>

                        {props.accessLevel && props.accessLevel === "admin" ? (
                            <MenuItem
                                onClick={() => navigate("/dashboard")}
                                style={{ color: textColor }}
                            >
                                Dashboard
                            </MenuItem>
                        ) : (
                            <></>
                        )}
                        <MenuItem
                            onClick={() => logOut()}
                            style={{ color: textColor }}
                        >
                            Sign Out
                        </MenuItem>
                    </Menu>
                </Grid>
            </View>

            <Card columnStart="2" columnEnd="3" className="all-tasks-card">
                {props.children}
            </Card>
        </Grid>
    );
}
