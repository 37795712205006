import { useEffect } from "react";

import {
    Authenticator,
    useAuthenticator,
    View,
    useTheme,
    Text,
    Heading,
    Button,
    Flex,
    Image,
} from "@aws-amplify/ui-react";

import styled from "styled-components";

const hostname =
    typeof window !== "undefined" ? window.location.hostname : "server";

const currentYear = new Date().getFullYear();

/*

const isRegistrationDisabled = hostname.includes("1stinfluencer") ? true : false;

const toMainPage = () => {
  window.location.href = "https://1stinfluencer.com";
}

{isRegistrationDisabled ?
  <Button
    fontWeight="normal"
    onClick={toMainPage}
    size="small"
    variation="link"
  >
    Start from the main page
  </Button>
  :
  <Button
    fontWeight="normal"
    onClick={toSignUp}
    size="small"
    variation="link"
  >
    Sign up
  </Button>
}


*/

const cssFor1stInfluencer = hostname.includes("1stinfluencer")
    ? `
  border:none;
  box-shadow: none;
  `
    : ``;
const StyledAuthenticator = styled(Authenticator)`
    .amplify-tabs {
        display: none;
    }
    .amplify-authenticator {
        border: none;
    }
    [data-amplify-router] {
        ${cssFor1stInfluencer}
    }
`;

export function Register(props) {
    const components = {
        Header() {
            const { tokens } = useTheme();

            return (
                <Flex
                    textAlign="center"
                    paddingTop={tokens.space.medium}
                    paddingBottom={tokens.space.medium}
                    justifyContent="center"
                    alignItems="center"
                    alignContent="center"
                    wrap="nowrap"
                >
                    {hostname.includes("1stinfluencer") ? (
                        <Image
                            alt="1stInfluencer logo"
                            src="/1stinfluencer/1stinfluencer-logo.png"
                            padding="0 10px"
                        />
                    ) : (
                        <Text
                            fontWeight={600}
                            fontSize="2.7em"
                            color={"#414c5a"}
                        >
                            LinkedIn Business
                            <br />
                            Influencer Marketplace
                        </Text>
                    )}
                </Flex>
            );
        },

        Footer() {
            const { tokens } = useTheme();

            return (
                <View textAlign="center" padding={tokens.space.large}>
                    {hostname.includes("1stinfluencer") ? (
                        <Text color={tokens.colors.neutral[80]}>
                            2022-{currentYear} &copy; All Rights Reserved
                        </Text>
                    ) : (
                        <Text color={tokens.colors.neutral[80]}>
                            2019-{currentYear} &copy; All Rights Reserved
                        </Text>
                    )}
                </View>
            );
        },

        SignIn: {
            Header() {
                const { tokens } = useTheme();

                return (
                    <Heading
                        padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                        level={3}
                        color={"#414c5a"}
                    >
                        Sign in to account
                    </Heading>
                );
            },
            Footer() {
                const { toResetPassword, toSignUp } = useAuthenticator();

                return (
                    <View textAlign="center">
                        <Button
                            fontWeight="normal"
                            onClick={toResetPassword}
                            size="small"
                            variation="link"
                        >
                            Forgot password?
                        </Button>
                        <br />
                        <br />
                        New here?
                        <br />
                        <Button
                            fontWeight="normal"
                            onClick={toSignUp}
                            size="small"
                            variation="link"
                        >
                            Sign up
                        </Button>
                        <br />
                        <br />
                    </View>
                );
            },
        },

        SignUp: {
            Header() {
                const { tokens } = useTheme();

                return (
                    <Heading
                        padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                        level={3}
                        color={"#414c5a"}
                    >
                        Please register to start
                    </Heading>
                );
            },
            Footer() {
                const { toResetPassword, toSignIn } = useAuthenticator();

                return (
                    <View textAlign="center">
                        <Button
                            fontWeight="normal"
                            onClick={toResetPassword}
                            size="small"
                            variation="link"
                        >
                            Forgot password?
                        </Button>
                        <br />
                        <br />
                        <Button
                            fontWeight="normal"
                            onClick={toSignIn}
                            size="small"
                            variation="link"
                        >
                            Back to Sign In
                        </Button>
                        <br />
                        <br />
                    </View>
                );
            },
        },
        ConfirmSignUp: {
            Header() {
                const { tokens } = useTheme();
                return (
                    <Heading
                        padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                        level={3}
                        color={"#414c5a"}
                    >
                        Enter the pin from email:
                    </Heading>
                );
            },
            Footer() {},
        },
        SetupTOTP: {
            Header() {
                const { tokens } = useTheme();
                return (
                    <Heading
                        padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                        level={3}
                        color={"#414c5a"}
                    >
                        Enter Information:
                    </Heading>
                );
            },
            Footer() {
                return <Text></Text>;
            },
        },
        ConfirmSignIn: {
            Header() {
                const { tokens } = useTheme();
                return (
                    <Heading
                        padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                        level={3}
                        color={"#414c5a"}
                    >
                        Enter Information:
                    </Heading>
                );
            },
            Footer() {
                return <Text></Text>;
            },
        },
        ResetPassword: {
            Header() {
                return (
                    <Heading padding={`0 0 0 0`} level={3} color={"#414c5a"}>
                        Reset password
                    </Heading>
                );
            },
            Footer() {},
        },
        ConfirmResetPassword: {
            Header() {
                return (
                    <Heading padding={`0 0 0 0`} level={3} color={"#414c5a"}>
                        Set new password
                    </Heading>
                );
            },
            Footer() {},
        },
    };

    const formFields = {
        signIn: {
            username: {
                labelHidden: false,
                placeholder: "e.g. john@business.com",
            },
        },
        signUp: {
            name: {
                labelHidden: false,
                label: "First Name",
                placeholder: "e.g. John",
                isRequired: true,
                order: 1,
            },
            email: {
                labelHidden: false,
                label: "Email",
                placeholder: "e.g. john@business.com",
                isRequired: true,
                order: 2,
            },
            password: {
                labelHidden: false,
                label: "Password (6 or more characters)",
                placeholder: "******",
                isRequired: false,
                order: 3,
            },
            confirm_password: {
                labelHidden: true,
                placeholder: "******",
                order: 4,
            },
            "custom:domain": {
                defaultValue: hostname,
                type: "hidden",
                custom: true,
                isRequired: true,
                order: 6,
            },
        },
        forceNewPassword: {
            password: {
                labelHidden: false,
                placeholder: "Enter your Password:",
            },
        },
        resetPassword: {
            username: {
                labelHidden: false,
                label: "Email",
                placeholder: "e.g. john@business.com",
                isRequired: true,
            },
        },
        confirmResetPassword: {
            confirmation_code: {
                labelHidden: false,
                placeholder: "6-digit code",
                label: "Reset code from email",
                isRequired: true,
            },
            password: {
                labelHidden: false,
                label: "Password (6 or more characters)",
                placeholder: "******",
                isRequired: true,
            },
            confirm_password: {
                labelHidden: true,
                placeholder: "******",
                isRequired: true,
            },
        },
        setupTOTP: {
            QR: {
                totpIssuer: "test issuer",
                totpUsername: "amplify_qr_test_user",
            },
            confirmation_code: {
                labelHidden: false,
                label: "New Label",
                placeholder: "Enter your Confirmation Code:",
                isRequired: false,
            },
        },
        confirmSignIn: {
            confirmation_code: {
                labelHidden: false,
                label: "New Label",
                placeholder: "Enter your Confirmation Code:",
                isRequired: false,
            },
        },
    };

    useEffect(() => {
        setTimeout(() => {
            const input = document.querySelector('input[name="email"]');

            const urlParams = new URLSearchParams(window.location.search);

            if (input && urlParams.has("email")) {
                input.setAttribute("value", urlParams.get("email"));
            }
        });
    }, []);

    return (
        <StyledAuthenticator
            formFields={formFields}
            components={components}
            initialState={props.initialState}
        ></StyledAuthenticator>
    );
}
