import { useState, useEffect } from "react";
import { Amplify, API, Auth, Storage } from "aws-amplify";
import config from "./aws-exports";
import {
    Authenticator,
    useAuthenticator,
    Loader,
    ThemeProvider,
} from "@aws-amplify/ui-react";
import { SimpleLayout } from "./ui-components/layout/SimpleLayout.js";
import { Layout } from "./ui-components/layout/Layout.js";
import {
    BrowserRouter,
    Routes,
    Route,
    Outlet,
    Navigate,
} from "react-router-dom";
import { RequireAuth } from "./RequireAuth";
import { Register } from "./ui-components/Register";
import { NewCampaign } from "./ui-components/NewCampaign";
import { CampaignHistory } from "./ui-components/CampaignHistory";
import { Billing } from "./ui-components/billing/Billing";
import { Earnings } from "./ui-components/Earnings";
import { AddProfile } from "./ui-components/AddProfile";
import { Dashboard } from "./ui-components/Dashboard";
import { Settings } from "./ui-components/Settings";
import { SpecificProfile } from "./ui-components/SpecificProfile";
import { MarketingReport } from "./ui-components/MarketingReport";
import { UsersReport } from "./ui-components/UsersReport";
import { CampaignsReport } from "./ui-components/CampaignsReport";
import { PayoutsReport } from "./ui-components/PayoutsReport";
import { Tasks } from "./ui-components/Tasks";

Amplify.configure(config);

const theme = {
    name: "influencerTheme",
    tokens: {
        colors: {
            brand: {
                primary: {
                    10: {
                        value: "hsl(210, 61%, 70%)",
                    },
                    20: {
                        value: "hsl(210, 60%, 64%)",
                    },
                    40: {
                        value: "hsl(210, 60%, 52%)",
                    },
                    60: {
                        value: "hsl(210, 90%, 40%)",
                    },
                    80: {
                        value: "hsl(210, 90%, 32%)",
                    },
                    90: {
                        value: "hsl(210, 90%, 24%)",
                    },
                    100: {
                        value: "hsl(210, 90%, 20%)",
                    },
                },
                secondary: {
                    10: {
                        value: "{colors.neutral.10.value}",
                    },
                    20: {
                        value: "{colors.neutral.20.value}",
                    },
                    40: {
                        value: "{colors.neutral.40.value}",
                    },
                    60: {
                        value: "{colors.neutral.60.value}",
                    },
                    80: {
                        value: "{colors.neutral.80.value}",
                    },
                    90: {
                        value: "{colors.neutral.90.value}",
                    },
                    100: {
                        value: "{colors.neutral.100.value}",
                    },
                },
            },
        },
    },
};

function MyRoutes() {
    const [isLoading, setLoading] = useState(true);

    const [balance, setBalance] = useState(0);
    const [profiles, setProfiles] = useState([]);
    const [accessLevel, setAccess] = useState("user");
    const [subscription, setSubscription] = useState(false);

    const [activeProfile, setActiveProfile] = useState({});

    const { user } = useAuthenticator((context) => [context.user]);

    const [clientData, setClientData] = useState({});

    async function getBalance() {
        var userLocal = user; //undefined;

        try {
            userLocal = await Auth.currentAuthenticatedUser();
        } catch (error) {
            userLocal = user;
        }

        //when just signed out both user & userLocal are undefined
        try {
            const requestBalance = {
                headers: {
                    Authorization: `${userLocal.signInUserSession.idToken.jwtToken}`,
                },
            };
            const data = await API.get(
                "gcAPI",
                `/get-client/${userLocal.username}`,
                requestBalance
            );
            //console.log('data', data);//payments "inactive"
            if (data.accessLevel && data.accessLevel === "admin")
                setAccess(data.accessLevel);
            if (data.payments && data.payments === "active")
                setSubscription(true);
            setBalance(data.starsBalance);
            if (data.profiles.length > 0) {
                var sortedProfiles = data.profiles.sort(
                    (a, b) => Number(b.validated) - Number(a.validated)
                );
                //use to refresh the token bafore refresh the pic signed url
                await Auth.currentAuthenticatedUser();
                setProfiles(
                    await Promise.all(
                        sortedProfiles.map(async (el) => {
                            el.avatarUrl = await Storage.get(el.avatarUrl, {
                                level: "protected",
                                identityId: userLocal.username,
                            });
                            return { ...el };
                        })
                    )
                );
            } else {
                setProfiles([]);
            }

            //set subscriptionPlan, payments, postBalance?, email, fiatBalance, fiatCurrency
            let clientDataLocal = {};
            if (data.subscriptionPlan)
                clientDataLocal.subscriptionPlan = data.subscriptionPlan;
            if (data.payments) clientDataLocal.payments = data.payments;
            if (data.postBalance)
                clientDataLocal.postBalance = data.postBalance;
            if (data.email) clientDataLocal.email = data.email;
            if (data.fiatBalance)
                clientDataLocal.fiatBalance = data.fiatBalance;
            if (data.fiatCurrency)
                clientDataLocal.fiatCurrency = data.fiatCurrency;
            if (data.subscriptionId)
                clientDataLocal.subscriptionId = data.subscriptionId;
            if (data.customerId) clientDataLocal.customerId = data.customerId;
            if (data.lifetimePayouts)
                clientDataLocal.lifetimePayouts = data.lifetimePayouts;
            if (data.comments) clientDataLocal.comments = data.comments;
            if (data.intros) clientDataLocal.intros = data.intros;
            if (data.pods) clientDataLocal.pods = data.pods;
            setClientData(clientDataLocal);
            setLoading(false);
        } catch (e) {
            console.log("USER NOT READY");
            console.log("error", e);
        }
    }

    useEffect(() => {
        if (user !== undefined) getBalance();
        // eslint-disable-next-line
    }, [user]);

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Outlet />}>
                    <Route
                        index
                        element={
                            <RequireAuth>
                                {isLoading ? (
                                    <Loader variation="linear" />
                                ) : (
                                    <Layout
                                        accessLevel={accessLevel}
                                        profiles={profiles}
                                        balance={balance}
                                        updateBalance={setBalance}
                                        aProfile={activeProfile}
                                        updateAProfile={setActiveProfile}
                                        clientData={clientData}
                                    >
                                        {!subscription ? (
                                            <Tasks
                                                updateProfiles={setProfiles}
                                                aProfile={activeProfile}
                                                updateAProfile={
                                                    setActiveProfile
                                                }
                                                profiles={profiles}
                                                balance={balance}
                                                updateBalance={setBalance}
                                            />
                                        ) : (
                                            <NewCampaign
                                                balance={balance}
                                                updateBalance={setBalance}
                                                subscription={subscription}
                                                clientData={clientData}
                                            />
                                        )}
                                    </Layout>
                                )}
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/tasks"
                        element={
                            <RequireAuth>
                                {isLoading ? (
                                    <Loader variation="linear" />
                                ) : (
                                    <Layout
                                        accessLevel={accessLevel}
                                        profiles={profiles}
                                        balance={balance}
                                        updateBalance={setBalance}
                                        aProfile={activeProfile}
                                        updateAProfile={setActiveProfile}
                                        clientData={clientData}
                                    >
                                        <Tasks
                                            updateProfiles={setProfiles}
                                            aProfile={activeProfile}
                                            updateAProfile={setActiveProfile}
                                            profiles={profiles}
                                            balance={balance}
                                            updateBalance={setBalance}
                                        />
                                    </Layout>
                                )}
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/newcampaign"
                        element={
                            <RequireAuth>
                                {isLoading ? (
                                    <Loader variation="linear" />
                                ) : (
                                    <Layout
                                        accessLevel={accessLevel}
                                        profiles={profiles}
                                        balance={balance}
                                        updateBalance={setBalance}
                                        aProfile={activeProfile}
                                        updateAProfile={setActiveProfile}
                                        clientData={clientData}
                                    >
                                        <NewCampaign
                                            balance={balance}
                                            updateBalance={setBalance}
                                            subscription={subscription}
                                            clientData={clientData}
                                        />
                                    </Layout>
                                )}
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/history"
                        element={
                            <RequireAuth>
                                {isLoading ? (
                                    <Loader variation="linear" />
                                ) : (
                                    <Layout
                                        accessLevel={accessLevel}
                                        profiles={profiles}
                                        balance={balance}
                                        updateBalance={setBalance}
                                        aProfile={activeProfile}
                                        updateAProfile={setActiveProfile}
                                        clientData={clientData}
                                    >
                                        <CampaignHistory
                                            aProfile={activeProfile}
                                        />
                                    </Layout>
                                )}
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/earnings"
                        element={
                            <RequireAuth>
                                {isLoading ? (
                                    <Loader variation="linear" />
                                ) : (
                                    <Layout
                                        accessLevel={accessLevel}
                                        profiles={profiles}
                                        balance={balance}
                                        updateBalance={setBalance}
                                        aProfile={activeProfile}
                                        updateAProfile={setActiveProfile}
                                        clientData={clientData}
                                    >
                                        <Earnings
                                            profiles={profiles}
                                        />                                        
                                    </Layout>
                                )}
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/billing"
                        element={
                            <RequireAuth>
                                {isLoading ? (
                                    <Loader variation="linear" />
                                ) : (
                                    <Layout
                                        accessLevel={accessLevel}
                                        profiles={profiles}
                                        balance={balance}
                                        updateBalance={setBalance}
                                        aProfile={activeProfile}
                                        updateAProfile={setActiveProfile}
                                        clientData={clientData}
                                    >
                                        <Billing
                                            clientData={clientData}
                                            updateClientData={setClientData}
                                            updateSubscription={setSubscription}
                                        />
                                    </Layout>
                                )}
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/addProfile"
                        element={
                            <RequireAuth>
                                {isLoading ? (
                                    <Loader variation="linear" />
                                ) : (
                                    <Layout
                                        accessLevel={accessLevel}
                                        profiles={profiles}
                                        balance={balance}
                                        updateBalance={setBalance}
                                        aProfile={activeProfile}
                                        updateAProfile={setActiveProfile}
                                        clientData={clientData}
                                    >
                                        <AddProfile
                                            updateProfiles={setProfiles}
                                            aProfile={activeProfile}
                                            updateAProfile={setActiveProfile}
                                            clientData={user?.username}
                                        />
                                    </Layout>
                                )}
                            </RequireAuth>
                        }
                    />

                    <Route
                        path="/dashboard"
                        element={
                            <RequireAuth>
                                {isLoading ? (
                                    <Loader variation="linear" />
                                ) : (
                                    <Layout
                                        accessLevel={accessLevel}
                                        profiles={profiles}
                                        balance={balance}
                                        updateBalance={setBalance}
                                        aProfile={activeProfile}
                                        updateAProfile={setActiveProfile}
                                        clientData={clientData}
                                    >
                                        {accessLevel === "admin" ? (
                                            <Dashboard
                                                updateProfiles={setProfiles}
                                                aProfile={activeProfile}
                                                updateAProfile={
                                                    setActiveProfile
                                                }
                                            />
                                        ) : (
                                            <>Access denied</>
                                        )}
                                    </Layout>
                                )}
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/dashboard/marketing-report"
                        element={
                            <RequireAuth>
                                {isLoading ? (
                                    <Loader variation="linear" />
                                ) : (
                                    <Layout
                                        accessLevel={accessLevel}
                                        profiles={profiles}
                                        balance={balance}
                                        updateBalance={setBalance}
                                        aProfile={activeProfile}
                                        updateAProfile={setActiveProfile}
                                        clientData={clientData}
                                    >
                                        {accessLevel === "admin" ? (
                                            <MarketingReport
                                                updateProfiles={setProfiles}
                                                aProfile={activeProfile}
                                                updateAProfile={
                                                    setActiveProfile
                                                }
                                            />
                                        ) : (
                                            <>Access denied</>
                                        )}
                                    </Layout>
                                )}
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/dashboard/campaigns-report"
                        element={
                            <RequireAuth>
                                {isLoading ? (
                                    <Loader variation="linear" />
                                ) : (
                                    <Layout
                                        accessLevel={accessLevel}
                                        profiles={profiles}
                                        balance={balance}
                                        updateBalance={setBalance}
                                        aProfile={activeProfile}
                                        updateAProfile={setActiveProfile}
                                        clientData={clientData}
                                    >
                                        {accessLevel === "admin" ? (
                                            <CampaignsReport
                                                profiles={profiles}
                                            />
                                        ) : (
                                            <>Access denied</>
                                        )}
                                    </Layout>
                                )}
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/dashboard/users-report"
                        element={
                            <RequireAuth>
                                {isLoading ? (
                                    <Loader variation="linear" />
                                ) : (
                                    <Layout
                                        accessLevel={accessLevel}
                                        profiles={profiles}
                                        balance={balance}
                                        updateBalance={setBalance}
                                        aProfile={activeProfile}
                                        updateAProfile={setActiveProfile}
                                        clientData={clientData}
                                    >
                                        {accessLevel === "admin" ? (
                                            <UsersReport
                                                updateProfiles={setProfiles}
                                                aProfile={activeProfile}
                                                updateAProfile={
                                                    setActiveProfile
                                                }
                                            />
                                        ) : (
                                            <>Access denied</>
                                        )}
                                    </Layout>
                                )}
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/dashboard/payouts-report"
                        element={
                            <RequireAuth>
                                {isLoading ? (
                                    <Loader variation="linear" />
                                ) : (
                                    <Layout
                                        accessLevel={accessLevel}
                                        profiles={profiles}
                                        balance={balance}
                                        updateBalance={setBalance}
                                        aProfile={activeProfile}
                                        updateAProfile={setActiveProfile}
                                        clientData={clientData}
                                    >
                                        <PayoutsReport profiles={profiles} accessLevel={accessLevel}/>
                                    </Layout>
                                )}
                            </RequireAuth>
                        }
                    />

                    <Route
                        path="/settings"
                        element={
                            <RequireAuth>
                                {isLoading ? (
                                    <Loader variation="linear" />
                                ) : (
                                    <Layout
                                        accessLevel={accessLevel}
                                        profiles={profiles}
                                        balance={balance}
                                        updateBalance={setBalance}
                                        aProfile={activeProfile}
                                        updateAProfile={setActiveProfile}
                                        clientData={clientData}
                                    >
                                        <Settings
                                            updateProfiles={setProfiles}
                                            aProfile={activeProfile}
                                            updateAProfile={setActiveProfile}
                                            profiles={profiles}
                                        />
                                    </Layout>
                                )}
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/settings/:profileId"
                        element={
                            <RequireAuth>
                                {isLoading ? (
                                    <Loader variation="linear" />
                                ) : (
                                    <Layout
                                        accessLevel={accessLevel}
                                        profiles={profiles}
                                        balance={balance}
                                        updateBalance={setBalance}
                                        aProfile={activeProfile}
                                        updateAProfile={setActiveProfile}
                                        clientData={clientData}
                                    >
                                        <SpecificProfile
                                            updateProfiles={setProfiles}
                                            aProfile={activeProfile}
                                            updateAProfile={setActiveProfile}
                                        />
                                    </Layout>
                                )}
                            </RequireAuth>
                        }
                    />

                    <Route
                        path="/signin"
                        element={
                            <SimpleLayout>
                                <Register initialState="signIn" />
                            </SimpleLayout>
                        }
                    />
                    <Route
                        path="/signup"
                        element={
                            <SimpleLayout>
                                <Register initialState="signUp" />
                            </SimpleLayout>
                        }
                    />
                    <Route
                        path="/passwordrestore"
                        element={
                            <SimpleLayout>
                                <Register initialState="resetPassword" />
                            </SimpleLayout>
                        }
                    />
                    <Route path="*" element={<Navigate to="/" />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default function App() {
    return (
        <ThemeProvider theme={theme}>
            <Authenticator.Provider>
                <MyRoutes />
            </Authenticator.Provider>
        </ThemeProvider>
    );
}
