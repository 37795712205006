import {
  Link,
  Heading,
  Text,
  View
} from '@aws-amplify/ui-react';


export function Dashboard({ updateProfiles, aProfile, updateAProfile }) {

  return (
    <View align="center">
      <Heading level={3} fontWeight={'bold'} textAlign="center">
        Dashboard
      </Heading>
      <View align="center">
        <Text variation="secondary">
          Access whole system data
        </Text>
      </View>
      <br />
      <br />
      <Link href="/dashboard/marketing-report">Marketing Report</Link>
      <br />
      <br />
      <Link href="/dashboard/campaigns-report">Campaigns Report</Link>
      <br />
      <br />
      <Link href="/dashboard/users-report">Users Report</Link>
      <br />
      <br />
      <Link href="/dashboard/payouts-report">Payouts Report</Link>
    </View>
  )
}